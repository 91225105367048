import { GET_ANALYTICS } from "./constants";
import { http } from "./httpServices";

export function getAnalytics(month, year) {
  return http.get(`${GET_ANALYTICS}`, {
    params: {
      month,
      year,
    },
  });
}
