import React from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./style.css";

const CalendarUI = ({ onDateSelect, value, view = "month",minDate='' }) => {
  const onSelect = (value) => {
    onDateSelect({ value });
  };
  return (
    <Calendar
      maxDate={new Date()}
      onClickMonth={onSelect}
      onChange={onSelect}
      value={value}
      view={view}
      minDate={minDate}
    />
  );
};

export default CalendarUI;
