import React from "react";
import { twMerge } from "../../utils";
import Box from "../Box";
import Icon from "../Icon";
import SidebarBadge from "./SidebarBadge";
import Typography from "../Typography";

// STYLES
const styles = {
  sidebarItem: "flex items-center gap-3 px-3 py-3 rounded cursor-pointer",
  activeSidebarItem: "bg-secondary/50",
  sidebarText: "text-font-light font-normal",
};

const SidebarItem = ({ item, active, icon, onNavigate }) => {
  return (
    <Box
      onClick={() => onNavigate(item.path)}
      className={twMerge(
        styles.sidebarItem,
        active && styles.activeSidebarItem
      )}
    >
      <Icon source={icon} alt="dashboard-icon" className="w-5" />
      <Typography component="span" className={styles.sidebarText}>
        {item.label}
      </Typography>
      {item?.count && <SidebarBadge value={item?.count} />}
    </Box>
  );
};

export default SidebarItem;
