import React from "react";
import Box from "../Box";
import Typography from "../Typography";
import Image from "../Image";

const styles = {
  imageListWrapper: "p-6 pb-2 flex flex-col gap-2 md:p-4 md:pb-2",
  invoiceLabelText: "text-font-secondary text-sm",
  invoicePreviewWrapper: "flex flex-row gap-4 flex-wrap",
  invoicePreview: "w-20 h-20 rounded",
};

const InvoiceImageList = ({ imageList = [], onViewImg }) => {
  return (
    <Box className={styles.imageListWrapper}>
      <Typography className={styles.invoiceLabelText}>Invoice</Typography>
      <Box className={styles.invoicePreviewWrapper}>
        {imageList.length > 0 &&
          imageList.map((item, idx) => (
            <Box onClick={() => onViewImg(item)}>
              <Image source={item} className={styles.invoicePreview} />
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default InvoiceImageList;
