import React from "react";

const Typography = ({ component, children, className, ...props }) => {
  const renderTypographyComponent = () => {
    switch (component) {
      case "div":
        return (
          <div className={className} {...props}>
            {children}
          </div>
        );
      case "span":
        return (
          <span className={className} {...props}>
            {children}
          </span>
        );
      default:
        return (
          <div className={className} {...props}>
            {children}
          </div>
        );
    }
  };
  return <>{renderTypographyComponent(component, children, className)}</>;
};

export default Typography;
