import React from "react";

const TableHeader = ({ columns }) => {
  return (
    <thead className="bg-secondary/5">
      <tr>
        {columns.map((column) => (
          <th align="left" key={column.path || column.key}>
            <div className="text-font-primary font-medium p-4">
              {column.label}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHeader;
