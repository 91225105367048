import React from "react";
import Box from "../Box";
import { ChevronDownIcon, UserAvatar } from "../../assets";
import { DUMMY_AVATAR } from "../../constants";
import Typography from "../Typography";
import Image from "../Image";
import Icon from "../Icon";
import ProfileData from "./ProfileData";
import { useClickAway } from "../../hooks";

// STYLES
const styles = {
  profileCardContainer:
    "flex flex-row relative items-center gap-2 cursor-pointer",
  profileTextDetailsContainer: "text-right mr-2 lg:hidden",
  name: "text-sm text-font-primary font-medium",
  designation: "text-xs text-font-secondary font-medium",
  avatar: "h-10 w-10 flex-none rounded-full bg-gray-50",
  profileDropdownContainer:
    "absolute flex flex-col right-0 z-20 top-14 w-96 origin-top-right rounded bg-white drop-shadow p-2 sm:w-80 xs:w-72",
};

const ProfileCard = ({
  userInfo,
  showProfile,
  onClickProfile,
  onCloseDropDown,
}) => {
  return (
    <ProfileDataWrapper onClickOutside={onCloseDropDown}>
      <Box className={styles.profileCardContainer}>
        <Box className={styles.profileTextDetailsContainer}>
          <Typography className={styles.name}>{userInfo?.name}</Typography>
          <Typography className={styles.designation}>
            {userInfo?.jobTitle}
          </Typography>
        </Box>
        <Box className={styles.profileCardContainer} onClick={onClickProfile}>
          <Image
            className={styles.avatar}
            source={UserAvatar}
            alt="power-wallet-avatar"
          />
          <Icon className="h-5 w-5 lg:hidden" source={ChevronDownIcon} alt="" />
        </Box>
        {showProfile && (
          <Box className={styles.profileDropdownContainer}>
            <ProfileData userInfo={userInfo} />
          </Box>
        )}
      </Box>
    </ProfileDataWrapper>
  );
};

const ProfileDataWrapper = ({ children, onClickOutside }) => {
  const ref = useClickAway(() => {
    onClickOutside();
  });
  return (
    <div className="md:w-full" ref={ref}>
      {children}
    </div>
  );
};

export default ProfileCard;
