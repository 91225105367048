import React from "react";
import { LoaderSVG } from "../assets";

const Loader = () => {
  return (
    <div className="absolute w-screen h-screen flex items-center justify-center bg-black/70 z-50">
      <img src={LoaderSVG} className="w-24" />
    </div>
  );
};

export default Loader;
