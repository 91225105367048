import React, { useEffect, useReducer, useState } from "react";
import Box from "../../components/Box";
import ClaimTable from "../../components/Claims/ClaimsTable";
import PageHeader from "../../components/Claims/PageHeader";
import SearchField from "../../components/SearchField";
import { getFormattedClaimersList } from "../../services/helpers";
import { getUsersList } from "../../services/userService";
import { toast } from "react-toastify";
import { useLoadContext } from "../../contexts/LoaderContext";
import { useNavigate } from "react-router";
import { DEFAULT_ERROR_MESSAGE } from "../../constants";
import {
  CLAIMERS_ACTIONS,
  claimersInitialState,
  claimersReducer,
} from "./reducers";

const useDebouncedValue = (inputValue, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(inputValue);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue, delay]);

  return debouncedValue;
};

const Claims = () => {
  const navigate = useNavigate();
  const [loading, setLoader] = useState(false);
  const [state, dispatch] = useReducer(claimersReducer, claimersInitialState);
  let { claimersDataCount, claimersData, rowsPerPage, page, searchText } =
    state;
  const debouncedSearchTerm = useDebouncedValue(searchText, 500);

  useEffect(() => {
    fetchUsersList(page, searchText);
  }, [debouncedSearchTerm]);

  const fetchUsersList = async (pageNo, query) => {
    let { rowsPerPage } = claimersInitialState;
    setLoader(true);
    try {
      let res = await getUsersList(pageNo, query, rowsPerPage);
      dispatch({
        type: CLAIMERS_ACTIONS.CALL_CLAIMERS_API,
        data: getFormattedClaimersList(res?.data?.body?.data),
        count: res?.data?.body?.count,
      });
    } catch (err) {
      toast.error(err?.message || DEFAULT_ERROR_MESSAGE);
    } finally {
      setLoader(false);
    }
  };

  const handlePageChange = (page) => {
    let { searchText } = state;
    dispatch({ type: CLAIMERS_ACTIONS.SET_PAGE, page });
    fetchUsersList(page, searchText);
  };

  const handleSearch = (e) => {
    const searchText = e.target.value;
    dispatch({ type: CLAIMERS_ACTIONS.SET_SEARCH_TEXT, searchText });
  };

  const handleClaimDetailsSelect = (item) => {
    navigate(`/admin/claim/${item?.id}`);
  };

  return (
    <Box className="p-8 md:p-4">
      <PageHeader />
      <Box className="bg-white p-4 rounded-sm border border-slate-200">
          <Box className="flex justify-end">
            <SearchField
              className="w-96 rounded-full"
              placeholder="Search for user, company, category"
              onSearch={handleSearch}
              value={searchText}
            />
          </Box>
        <Box className="pt-4">
          <ClaimTable
            data={claimersData}
            onPageChange={handlePageChange}
            isTableDataLoading={loading}
            totalData={claimersDataCount}
            currentPage={page}
            rowsPerPage={rowsPerPage}
            onClaimDetailsSelect={handleClaimDetailsSelect}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Claims;
