import React from "react";
import { twMerge } from "../utils";

const Textarea = ({ value, placeholder, onChange, className, rows = 2 }) => {
  return (
    <textarea
      rows={rows}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      className={twMerge(
        "w-full p-2 text-sm resize-none focus:outline-none rounded ring-1 ring-slate-200 placeholder:text-xs",
        className
      )}
    ></textarea>
  );
};

export default Textarea;
