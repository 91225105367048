import React from "react";
import Box from "../Box";
import Typography from "../Typography";
import { twMerge } from "../../utils";
import dayjs from "dayjs";

const styles = {
  detailedStatusWrapper: "bg-slate-200 p-4 md:p-2",
  detailedStatusLabel: "text-font-secondary text-sm text-center pb-4",
  statusWrapper: "flex items-center justify-between p-4 md:p-2 md:gap-2",
  roleText: "text-font-primary text-sm uppercase",
  nameText: "text-font-secondary text-xs capitalize",
  statusText: "font-semibold text-sm capitalize",
};

const getStatusMessage = (item) => {
  if (item.status === "rejected" || item.status === "approved") {
    return `${item?.userInfo?.name} ${item.status.toLowerCase()} on ${dayjs(item.date).format(
      "DD MMM YYYY"
    )}`;
  } else {
    return item?.suggestedUserInfo?.name !== undefined ? `Pending with ${item?.suggestedUserInfo?.name}` : 'Pending';
  }
};

const DetailedStatus = ({ data }) => {
  return (
    <Box className={styles.detailedStatusWrapper}>
      <Typography className={styles.detailedStatusLabel}>
        Detailed Status
      </Typography>
      {data?.length > 0 &&
        data
          .filter((items) => items.status !== "pending")
          .map((item) => (
            <Box className={styles.statusWrapper}>
              <Box>
                <Typography className={styles.roleText}>
                  {item.approver}
                </Typography>
                <Typography className={styles.nameText}>
                  {getStatusMessage(item)}
                </Typography>
              </Box>
              <Box>
                <Typography
                  className={twMerge(styles.statusText)}
                  style={{ color: item?.colorCode || "#CCC" }}
                >
                  {item?.status}
                </Typography>
              </Box>
            </Box>
          ))}
    </Box>
  );
};

export default DetailedStatus;
