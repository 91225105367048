import React from "react";
import Box from "../Box";
import Image from "../Image";
import { EmptyClaimList } from "../../assets";
import Typography from "../Typography";

const EmptyClaimState = () => {
  return (
    <Box className="flex justify-center flex-col items-center">
      <Box className="w-1/3 md:w-1/2">
        <Image source={EmptyClaimList} className="w-full" />
      </Box>
      <Box className="flex flex-col gap-1 mt-4 text-center">
        <Typography className="text-base text-font-primary">
          Sorry, No Result Found!
        </Typography>
        <Typography className="text-sm text-font-secondary">
          we could not find any results matching your search.
        </Typography>
      </Box>
    </Box>
  );
};

export default EmptyClaimState;
