import React, { Component, useEffect, useState } from "react";
import Box from "../../components/Box";
import ReportsTable from "../../components/Reports/ReportsTable";
import SearchField from "../../components/SearchField";
import { getSettings } from "../../services/settingsServices";
import {
  CLAIM_REQUEST_DATE_FILTER,
  DEFAULT_ERROR_MESSAGE,
  REPORTS_STATUS_FILTER,
} from "../../constants";
import { useLoadContext } from "../../contexts/LoaderContext";
import { toast } from "react-toastify";
import StatusFilter from "../../components/ClaimDetails/StatusFilter";
import PageHeader from "../../components/ClaimDetails/PageHeader";
import { useNavigate } from "react-router-dom";
import { downloadReports, getReports } from "../../services/expenseServices";
import dayjs from "dayjs";

const LIMIT = 10;
const initialState = {
  limit: LIMIT,
  page: 1,
  startDate: "",
  endDate: "",
  day: CLAIM_REQUEST_DATE_FILTER[1].value,
  status: "all",
  search: "",
};

const Reports = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState("");
  const [expenseStatusList, setExpenseStatusList] = useState([]);
  const [showDateFilter, setIfShowDateFilter] = useState(false);
  const [customFilterDates, setCustomFilterDates] = useState({
    dateEnd: "",
    dateStart: "",
  });
  const [dateAs, setDateAs] = useState(CLAIM_REQUEST_DATE_FILTER[1].value);
  const [isCustom, setIfCustom] = useState(false);
  const [customDatePickerModal, setCustomDatePickerModal] = useState({
    open: false,
    type: "",
  });
  const [searchText, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [listData, setListData] = useState([]);
  const { loader, setLoader } = useLoadContext();

  useEffect(() => {
    fetchReports({ ...initialState });
    setExpenseStatusList(REPORTS_STATUS_FILTER);
  }, []);

  const fetchReports = async (params) => {
    setLoader(true);
    let postableQueries = JSON.parse(JSON.stringify(params));
    let { dateStart, dateEnd } = customFilterDates;
    postableQueries.dateStart = dateStart
      ? dayjs(dateStart).format("YYYY/MM/DD")
      : "";
    postableQueries.dateEnd = dateEnd
      ? dayjs(dateEnd).format("YYYY/MM/DD")
      : "";
    postableQueries.dateAs =
      postableQueries.dateAs === "custom" ? "" : postableQueries.dateAs;
    try {
      let res = await getReports(postableQueries);
      setListData(res?.data?.body?.data);
      setCount(res?.data?.body?.count);
    } catch (err) {
      toast.error(err?.message || DEFAULT_ERROR_MESSAGE);
    } finally {
      setLoader(false);
    }
  };

  const handleApply = () => {
    setDateAs("custom");
    setIfCustom(true);
    setIfShowDateFilter(false);
    setPage(1);
    let paramsObj = {
      limit: LIMIT,
      page: 1,
      startDate: customFilterDates.dateStart,
      endDate: customFilterDates.dateEnd,
      day: "",
      status,
      searchText,
    };
    fetchReports(paramsObj);
  };
  const handleDateFilterSelection = (filter) => {
    if (filter.value === "custom") {
      setIfCustom(true);
      setIfShowDateFilter(true);
    } else {
      setCustomFilterDates({ dateEnd: "", dateStart: "" });
      setDateAs(filter.value);
      setIfShowDateFilter(false);
      setIfCustom(false);
      setPage(1);
      let paramsObj = {
        limit: LIMIT,
        page: 1,
        startDate: "",
        endDate: "",
        day: filter.value,
        status,
        searchText,
      };
      fetchReports(paramsObj);
    }
  };
  const handleCustomDatePickerSelect = (type) => {
    setCustomDatePickerModal({ open: true, type });
  };
  const handleCustomDateSelect = (data) => {
    setCustomFilterDates({ ...customFilterDates, [data.type]: data.value });
    setCustomDatePickerModal({ open: false, type: "" });
  };
  const handleStatusFilterSelect = (filterValue) => {
    setStatus(filterValue);
    setPage(1);
    let paramsObj = {
      limit: LIMIT,
      page: 1,
      startDate: customFilterDates.dateStart,
      endDate: customFilterDates.dateEnd,
      day: dateAs === 'custom' ? '': dateAs,
      status: filterValue,
      searchText,
    };
    fetchReports(paramsObj);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setPage(1);
    let paramsObj = {
      limit: LIMIT,
      page: 1,
      startDate: customFilterDates.dateStart,
      endDate: customFilterDates.dateEnd,
      day: dateAs === 'custom' ? '': dateAs,
      status,
      searchText: e.target.value,
    };
    fetchReports(paramsObj);
  };

  const handlePageChange = (page) => {
    setPage(page);
    let paramsObj = {
      limit: LIMIT,
      page,
      startDate: customFilterDates.dateStart,
      endDate: customFilterDates.dateEnd,
      day: dateAs === 'custom' ? '': dateAs,
      status,
      searchText,
    };
    fetchReports(paramsObj);
  };

  const handleDownload = async () => {
    setLoader(true);
    let postableQueries = JSON.parse(JSON.stringify(customFilterDates));
    let { dateStart, dateEnd } = customFilterDates;
    postableQueries.dateStart = dateStart
      ? dayjs(dateStart).format("YYYY/MM/DD")
      : "";
    postableQueries.dateEnd = dateEnd
      ? dayjs(dateEnd).format("YYYY/MM/DD")
      : "";
    try {
      let res = await downloadReports({
        startDate: postableQueries.dateStart,
        endDate: postableQueries.dateEnd,
        day: dateAs === "custom" ? "" : dateAs,
        status: status,
        searchText: searchText,
      });
      if (res.status === 200) {
        const outputFilename = `report.csv`;
        const url = URL.createObjectURL(
          new Blob([res.data], {
            type: "text/csv;charset=utf-8",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", outputFilename);
        document.body.appendChild(link);
        link.click();
        setLoader(false);
      }
    } catch (err) {
      toast.error(err?.message || DEFAULT_ERROR_MESSAGE);
    } finally {
      setLoader(false);
    }
  };
  return (
    <Box className="p-8 md:p-4">
      <PageHeader
        onDownload={handleDownload}
        showDownload={true}
        showBreadCrumb={false}
        name={"Reports"}
        customFilterDates={customFilterDates}
        isCustom={isCustom}
        dateAs={dateAs}
        showDateFilter={showDateFilter}
        dateFilterData={CLAIM_REQUEST_DATE_FILTER}
        onDateFilterSelection={handleDateFilterSelection}
        onCustomDatePickerSelect={handleCustomDatePickerSelect}
        onDateSelect={handleCustomDateSelect}
        customDatePickerModal={customDatePickerModal}
        onDateFilterSelect={(val) => {
          if (!customDatePickerModal.open) {
            setIfShowDateFilter(val);
          }
        }}
        onApply={handleApply}
        onCalendarModalClose={() =>
          setCustomDatePickerModal({ open: false, type: "" })
        }
        onClaimRequestClick={() => navigate("/admin/claims")}
      />
      <StatusFilter
        onSelectStatusFilter={handleStatusFilterSelect}
        active={status}
        filterOptions={expenseStatusList}
      />
      <Box className="bg-white p-4 rounded-sm border border-slate-200">
        <Box className="flex justify-end">
          <SearchField
            className="w-96 rounded-full"
            placeholder="Search for name, company, invoice"
            onSearch={handleSearch}
            value={searchText}
          />
        </Box>
        <Box className="pt-4">
          <ReportsTable
            data={listData}
            onPageChange={handlePageChange}
            isTableDataLoading={loader}
            totalData={count}
            currentPage={page}
            rowsPerPage={LIMIT}
            onClaimDetailsSelect={() => console.log()}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Reports;
