export const EXPENSE_LIST = "/expenseList";
export const USERS_LIST = "/userList";
export const APPROVE = "/approve";
export const SETTINGS = "/settings";
export const GET_ANALYTICS = "/getAnalytics";
export const USER_INFO = "/userInfo";
export const GET_COMMUNICATION = "/fetchCommunication";
export const COMMUNICATION = "/communication";
export const UPDATE_EXPENSE_STATUS = "/updateExpenseStatus";
export const UPDATE_MULTI_EXPENSE_STATUS = "/updateMultiExpenseStatus";
export const NOTIFICATION_LIST = "/notificationList";
export const GET_EXPENSE = "/getExpense";
export const SSO_LOGIN = "/ssoLogin"
export const DOWNLOAD_REPORTS = "/downloadExpenseReport";