import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import Box from "../Box";
import Typography from "../Typography";
import { REQUEST_ANALYTICS_OPTIONS } from "../../constants";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const getData = (data) => {
  return {
    labels: data.map((label) => label.day),
    datasets: [
      {
        barThickness: 16,
        label: "",
        data: data.map((label) => label.records.length),
        backgroundColor: "#588EB5",
        borderRadius: 4,
      },
    ],
  };
};

const styles = {
  chartContainer: "bg-white p-4 drop-shadow-sm lg:overflow-x-auto",
  chartTitle: "font-semibold text-lg text-primary mb-4",
  chartWrapper: "w-full lg:w-[1075px]",
};

const RequestAnalytics = ({ data }) => {
  const analyticsData = getData(data);
  return (
    <Box className={styles.chartContainer}>
      <Typography className={styles.chartTitle}>Request Analytics</Typography>
      <Box className={styles.chartWrapper}>
        <Bar
          options={REQUEST_ANALYTICS_OPTIONS}
          data={analyticsData}
          height="300px"
          width="100%"
        />
      </Box>
    </Box>
  );
};

export default RequestAnalytics;
