import React from "react";
import Box from "../Box";
import Button from "../Button";

const styles = {
  buttonWrapper: "p-6 flex gap-4 md:flex-col md:p-4 md:pb-6",
};

const ResponseAction = ({
  onApprove,
  onClarify,
  onDecline,
  comments,
  communication,
  isPhysicalCopyReceived,
  isAllAmountVerified,
  nextApproverRole,
  claimStatus,
}) => {
  let roles = localStorage.getItem("powerwallet-role");
  let isAuthorized = roles.includes(nextApproverRole);
  let isCompleted = ["approved", "declined", "rejected"].includes(claimStatus);

  return (
    <Box className={styles.buttonWrapper}>
      {isAuthorized && !isCompleted && (
        <>
          <Button
            className="w-1/4 md:w-full"
            variant="outlined-primary"
            onClick={onClarify}
            disabled={communication.length > 4}
          >
            CLARIFY
          </Button>
          <Button
            className="w-1/4 md:w-full"
            variant="outlined-critical"
            onClick={onDecline}
            disabled={!comments}
          >
            DECLINE
          </Button>
          <Button
            className="w-2/4 md:w-full"
            variant="filled-primary"
            onClick={onApprove}
            disabled={!isPhysicalCopyReceived || !isAllAmountVerified}
          >
            APPROVE
          </Button>
        </>
      )}
    </Box>
  );
};

export default ResponseAction;
