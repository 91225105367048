import React from "react";
import Box from "../Box";
import Button from "../Button";
import Icon from "../Icon";
import { LogoutIcon } from "../../assets";

// STYLES
const styles = {
  logoutButtonContainer: "mt-auto pl-3 pt-2",
  logoutButton: "flex flex-row text-font-light items-center gap-2",
};

const LogoutButton = ({ onLogout }) => {
  return (
    <Box className={styles.logoutButtonContainer}>
      <Button className={styles.logoutButton} onClick={onLogout}>
        <Icon source={LogoutIcon} alt="power-wallet-logout" className="w-6" />
        <Box>Logout</Box>
      </Button>
    </Box>
  );
};

export default LogoutButton;
