import { NOTIFICATION_LIST } from "./constants";
import { http } from "./httpServices";

export const getNotifications = (limit = 30, page = 1) => {
  return http.get(`${NOTIFICATION_LIST}`, {
    params: {
      limit,
      page,
    },
  });
};
