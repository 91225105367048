import md5 from "md5";

export const getEncodedHeader = function (data, query, method = "post") {
  let body;

  if (method === "post") {
    if (data) {
      body = Object.assign({}, data, query);
    } else {
      body = Object.assign({}, query, query);
    }
  } else if (method === "put") {
    body = Object.assign({}, data, query);
  } else {
    body = Object.assign({}, query, query);
  }

  const jsonString = JSON.stringify(body, (key, value) => {
    if (typeof value === "number") {
      return value.toString();
    }
    return value;
  });

  let mergeDeviceId = query.deviceId + jsonString;
  let md5encode = md5(mergeDeviceId);

  return md5encode;
};
