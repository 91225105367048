import { SETTINGS } from "./constants";
import { http } from "./httpServices";

export function getSettings() {
  return http.get(`${SETTINGS}`, {
    params: {
      type: "app",
    },
  });
}
