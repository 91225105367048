import React from "react";
import Box from "../Box";
import Button from "../Button";
import Modal from "../Modal";
import Checkbox from "../Checkbox";
import Textarea from "../Textarea";

const styles = {
  modalContainer: "flex justify-center flex-col items-center pb-2 w-96",
  modalHeaderText: "text-base font-semibold text-center",
  commentLabel: "text-xs font-semibold text-left mb-1",
  modalSubText: "mt-4 text-base text-center font-medium w-8/12",
  actionContainer:
    "flex mt-4 gap-4 justify-center flex-row items-center w-full",
  buttonStyle: "w-1/2",
  clarifyText: "mt-2 mb-2 text-slate-600 text-xs self-start w-60",
};

const AcknowledgeModal = ({
  showAcknowledgeModal,
  onClose,
  onContinue,
  onAcknowledgementChange,
  isAllAmountVerified,
  isPhysicalCopyReceived,
  onCommentChange,
  bulkComment,
  loading
}) => {
  return (
    <Modal open={showAcknowledgeModal} onClose={onClose}>
      <Box className={styles.modalHeaderText}>Acknowledgment</Box>
      <Box className={styles.modalContainer}>
        <Box className="flex flex-col px-2 py-2 gap-2">
          <Box className='my-2'>
            <Box className={styles.commentLabel}>Comment</Box>
            <Textarea
              value={bulkComment}
              placeholder="Comment"
              onChange={onCommentChange}
              className=""
              rows={2}
            />
          </Box>
          <Checkbox
            selected={isPhysicalCopyReceived}
            onToggle={(val) =>
              onAcknowledgementChange(val, "isPhysicalCopyReceived")
            }
            rightLabel="By checking this box, I acknowledge receipt of the original invoices/documents"
            className="text-primary text-sm text-left"
          />
          <Checkbox
            selected={isAllAmountVerified}
            onToggle={(val) =>
              onAcknowledgementChange(val, "isAllAmountVerified")
            }
            rightLabel="By checking this box, I confirm that I have verified the statement amount, invoice amount, and claim amount, ensuring they match accurately"
            className="text-primary text-sm text-left"
          />
        </Box>
        <Box className={styles.actionContainer}>
          <Button
            className={styles.buttonStyle}
            variant="outlined-primary"
            onClick={onClose}
          >
            CANCEL
          </Button>
          <Button
            className={styles.buttonStyle}
            variant="filled-primary"
            onClick={onContinue}
            disabled={!isAllAmountVerified || !isPhysicalCopyReceived || !bulkComment || loading}
          >
            CONFIRM
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AcknowledgeModal;
