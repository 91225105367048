import React from "react";
import Box from "../components/Box";
import Typography from "../components/Typography";
import Button from "../components/Button";
import { useNavigate } from "react-router-dom";

const styles = {
  notfoundContainer:
    "grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8 font-poppins",
  notFoundCode: "text-base text-secondary",
  notFoundWrapper: "text-center",
  notFoundText:
    "mt-4 text-3xl font-bold tracking-tight text-tx-primary sm:text-5xl",
  notFoundMessage: "mt-6 text-base leading-7 text-gray-600",
  actionContainer: "mt-10 flex items-center justify-center gap-x-6",
  loginButton:
    "bg-white text-sm rounded-full ring-1 ring-white text-tx-primary",
};

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <Box component="main" className={styles.notfoundContainer}>
      <Box className={styles.notFoundWrapper}>
        <Typography className={styles.notFoundCode}>404</Typography>
        <Typography className={styles.notFoundText}>Page not found</Typography>
        <Typography className={styles.notFoundMessage}>
          Sorry, we couldn’t find the page you’re looking for.
        </Typography>
        <Box className={styles.actionContainer}>
          <Button variant="filled-secondary" onClick={() => navigate("/admin")}>
            Go back home
          </Button>
          <Typography component="span">or</Typography>
          <Button className={styles.loginButton}>Login &rarr;</Button>
        </Box>
      </Box>
    </Box>
  );
};

export default NotFound;

// TODO : Navigate to respective pages based on button click (Go back home and Login)
