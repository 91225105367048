import React from "react";
import Box from "../Box";
import Typography from "../Typography";

// STYLES
const styles = {
  userDetailsContainer: "flex flex-col text-left gap-2 relative md:gap-0",
  name: "text-sm text-font-primary font-medium",
  designation: "text-xs text-font-secondary font-medium",
  newClaim: "bg-secondary absolute -left-4 -top-2.5 h-16 w-0.5",
};

const CompanyNameColumn = ({ name, company, isNew }) => {
  return (
    <Box className={styles.userDetailsContainer}>
      {isNew && <Box component="span" className={styles.newClaim} />}
      <Typography className={styles.name}>{name}</Typography>
      <Typography className={styles.designation}>{company}</Typography>
    </Box>
  );
};

export default CompanyNameColumn;
