import React from "react";
import Box from "../Box";
import Typography from "../Typography";

// STYLES
const styles = {
  profileDetailContainer:
    "flex flex-col items-start gap-2 cursor-pointer max-h-96 overflow-auto",
  headingText:
    "text-xs text-font-primary font-semibold underline underline-offset-1 mb-1 uppercase",
  subHeadingText: "text-xs text-font-secondary",
  detailText: "text-sm text-font-primary",
};

const ProfileDetailData = ({ title, details }) => {
  return (
    <Box className="mb-1.5">
      <Typography className={styles.subHeadingText}>{title}</Typography>
      <Typography className={[styles.detailText, "mt-1"]}>{details}</Typography>
    </Box>
  );
};

const ProfileData = ({ userInfo }) => {
  return (
    <Box className={styles.profileDetailContainer}>
      <Box className="border-b-1">
        <Typography className={styles.headingText}>Role</Typography>
        <Box className="flex flex-row gap-1">
          {userInfo?.role.map((item) => (
            <Typography className={styles.detailText}>
              {item.toUpperCase()},
            </Typography>
          ))}
        </Box>
      </Box>
      <Box className="border-b-1">
        <Typography className={styles.headingText}>Outlet Details</Typography>
        <Box className="flex flex-wrap">
          {userInfo?.outlets.map((item) => (
            <Typography className={styles.detailText}>
              {item?.outletId?.name.toUpperCase()},
            </Typography>
          ))}
        </Box>
      </Box>
      <Box className="border-b-1">
        <Typography className={styles.headingText}>Profile Details</Typography>
        <Box>
          <ProfileDetailData title="Employee Id" details={userInfo?.empId} />
          <ProfileDetailData title="Work Email" details={userInfo?.email} />
          <ProfileDetailData title="Phone Number" details={userInfo?.phone} />
          <ProfileDetailData title="Job Title" details={userInfo?.jobTitle} />
          <ProfileDetailData title="Company" details={userInfo?.companyName} />
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileData;
