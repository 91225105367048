import React from "react";
import Box from "../Box";
import Button from "../Button";
import Icon from "../Icon";
import { CalendarIcon } from "../../assets";
import { twMerge } from "../../utils";
import dayjs from "dayjs";

const styles = {
  customFilterDateContainer: "flex flex-col gap-4",
  customFilterFieldWrapper: "flex gap-4",
  customFilterField:
    "w-1/2 flex items-center gap-2 bg-white p-2.5 text-sm border-2 border-secondary/50 rounded",
};

const CustomDateFilter = ({
  customFilterDates,
  onDatePickerSelect,
  onApply,
}) => {
  return (
    <Box className={styles.customFilterDateContainer}>
      <Box className={styles.customFilterFieldWrapper}>
        <Button
          className={twMerge(
            styles.customFilterField,
            customFilterDates.dateStart
              ? "text-font-primary"
              : "text-font-secondary"
          )}
          onClick={() => onDatePickerSelect("dateStart")}
        >
          <Icon source={CalendarIcon} className="w-6" />
          {customFilterDates?.dateStart
            ? dayjs(customFilterDates.dateStart).format("DD/MM/YYYY") ||
              "Select Date"
            : "Select Date"}
        </Button>
        <Button
          className={twMerge(
            styles.customFilterField,
            customFilterDates.dateEnd
              ? "text-font-primary"
              : "text-font-secondary"
          )}
          onClick={() => onDatePickerSelect("dateEnd")}
        >
          <Icon source={CalendarIcon} className="w-6" />
          {customFilterDates?.dateEnd
            ? dayjs(customFilterDates.dateEnd).format("DD/MM/YYYY") ||
              "Select Date"
            : "Select Date"}
        </Button>
      </Box>
      <Button
        onClick={onApply}
        variant="filled-secondary"
        className="rounded-sm"
        disabled={!(customFilterDates.dateEnd && customFilterDates.dateStart)}
      >
        Apply
      </Button>
    </Box>
  );
};

export default CustomDateFilter;
