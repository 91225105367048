import { ClaimIcon, DashboardIcon, ReportsIcon, RequestIcon } from "./assets";

export const getSidebarIcon = (key) => {
  let keyObj = {
    dashboard: DashboardIcon,
    claims: ClaimIcon,
    reports: ReportsIcon,
  };
  return keyObj[key] || DashboardIcon;
};

export const getParentPath = (path) => {
  let routeObj = {
    dashboard: "dashboard",
    claims: "claims",
    claim: "claims",
    reports: "reports",
  };

  return routeObj[path];
};

export const getClaimStatusClass = (key) => {
  let statusObj = {
    pending: "ring-blue-500/10 bg-blue-50 text-blue-600",
    awaitingClarification: "ring-amber-500/10 bg-amber-50 text-amber-600",
    approved: "ring-green-500/10 bg-green-50 text-green-600",
    rejected: "ring-red-500/10 bg-red-50 text-red-600",
  };
  return statusObj[key] || "ring-gray-500/10 bg-gray-50 text-gray-600";
};

export const hasApproverRole = (rolesArray) => {
  let toRemove = ["bot", "user", "manager"]; // IF ANY ROLE IS ADDED OTHER THAN APPROVER ROLES, ADD IT TO THIS ARRAY.
  let approverRoles = [];
  approverRoles = rolesArray.filter((el) => !toRemove.includes(el));
  return approverRoles.length > 0 ? true : false;
};
