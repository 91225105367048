import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Box from "../Box";

const styles = {
  backgroundContainer:
    "fixed inset-0 bg-black bg-opacity-50 transition-opacity",
  mainContainer: "fixed inset-0 z-50 w-screen overflow-y-auto",
  innerContainer:
    "flex min-h-full justify-center p-4 text-center items-center sm:p-0",
  dailogStyle:
    "relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg p-4",
};

const Modal = ({ open, onClose, children }) => {
  const containerRef = useRef(null);

  return (
    <Transition.Root show={open} className='z-60'>
      <Dialog
        as="div"
        className="relative z-60"
        initialFocus={containerRef}
        onClose={onClose}
      >
        <Transition.Child
          // as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Box className={styles.backgroundContainer} />
        </Transition.Child>

        <Box className={styles.mainContainer}>
          <Box className={styles.innerContainer}>
            <Transition.Child
              // as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className={styles.dailogStyle} ref={containerRef}>
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </Box>
        </Box>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
