import React from "react";
import Box from "../Box";
import Typography from "../Typography";
import DateFilter from "./DateFilter";
import Image from "../Image";
import { DownloadIcon } from "../../assets";

// STYLES
const styles = {
  pageHeaderWrapper:
    "flex justify-between items-center pb-4 md:flex-col lg:gap-4 md:items-start md:w-full",
  pageHeaderText: "text-xl font-bold text-font-primary",
};

const PageHeader = ({
  showDownload = false,
  showBreadCrumb = true,
  name,
  dateFilterData,
  onDateFilterSelection,
  dateAs,
  isCustom,
  customFilterDates,
  onCustomDatePickerSelect,
  onDateSelect,
  customDatePickerModal,
  showDateFilter,
  onDateFilterSelect,
  onApply,
  onCalendarModalClose,
  onClaimRequestClick,
  onDownload,
}) => {
  return (
    <Box className={styles.pageHeaderWrapper}>
      <Box className="flex items-center gap-1">
        {showBreadCrumb && (
          <>
            <Box
              onClick={() => onClaimRequestClick()}
              className="cursor-pointer"
            >
              <Typography className="text-sm text-secondary underline">
                Claim Requests
              </Typography>
            </Box>
            <Typography component="span" className="text-sm text-primary">
              {">"}
            </Typography>
          </>
        )}
        <Typography className={styles.pageHeaderText}>{name}</Typography>
      </Box>
      <Box className="md:w-full flex gap-2" >
        <DateFilter
          showDateFilter={showDateFilter}
          customFilterDates={customFilterDates}
          isCustom={isCustom}
          dateAs={dateAs}
          dateFilterData={dateFilterData}
          onDateFilterSelection={onDateFilterSelection}
          onCustomDatePickerSelect={onCustomDatePickerSelect}
          onDateSelect={onDateSelect}
          customDatePickerModal={customDatePickerModal}
          onDateFilterSelect={onDateFilterSelect}
          onApply={onApply}
          onCalendarModalClose={onCalendarModalClose}
        />
      {showDownload && (
        <Box onClick={onDownload} className='bg-white px-4 py-3  rounded ring-1 ring-slate-200'>
          <Image source={DownloadIcon} className='w-4'/>
        </Box>
      )}
      </Box>
    </Box>
  );
};

export default PageHeader;
