import React from "react";
import { twMerge } from "../utils";

const InputField = ({
  value,
  placeholder,
  onChange,
  className,
  label,
  required,
}) => {
  return (
    <div className="flex flex-col gap-1">
      <label className="text-font-secondary text-sm">{label}{required && <span className="text-red-500">*</span>}</label>
      <input
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        className={twMerge(
          "w-full p-3 text-sm resize-none focus:outline-none rounded ring-1 ring-slate-200 placeholder:text-xs",
          className
        )}
      />
    </div>
  );
};

export default InputField;
