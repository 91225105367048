import React from "react";
import Box from "../Box";
import Typography from "../Typography";
import { twMerge } from "../../utils";

const styles = {
  categoryWrapper: "relative w-full",
  categoryPlaceholder: "bg-white w-full h-12 rounded relative flex",
  categoryProgress: "h-full rounded-r bg-slate-100",
  categoryLabel: "text-sm text-font-primary capitalize",
  categoryLabelContainer: "absolute flex h-full items-center px-2",
  categoryCountContainer: "absolute right-0 top-0",
  categoryCountWrapper: "flex items-center gap-2 h-12",
  categoryCountText: "w-24 text-center text-sm",
};

const CategoriesCount = ({ label, value, pending, approved, rejected }) => {
  return (
    <Box className={styles.categoryWrapper}>
      <Box className="gap-2">
        <Box className={styles.categoryPlaceholder}>
          <Box
            className={twMerge(styles.categoryProgress)}
            style={{ width: value }}
          />
          <Box className={styles.categoryLabelContainer}>
            <Typography className={styles.categoryLabel}>{label}</Typography>
          </Box>
        </Box>
      </Box>
      <Box className={styles.categoryCountContainer}>
        <Box className={styles.categoryCountWrapper}>
          <Typography className={styles.categoryCountText}>{pending}</Typography>
          <Typography className={styles.categoryCountText}>{approved}</Typography>
          <Typography className={styles.categoryCountText}>{rejected}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CategoriesCount;
