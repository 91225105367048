export const generateForwardArray = (firstElement, length, limit) => {
  return Array.from(
    { length: limit > length ? length : limit },
    (_, index) => firstElement + index
  );
};

export const generateForwardArrayTrunc = (firstElement, length, limit) => {
  if (limit < firstElement + 2 * length - 1) {
    return Array.from(
      { length: length },
      (_, index) => limit - length + index + 1
    );
  }
  return Array.from(
    { length: limit > length ? length : limit },
    (_, index) => firstElement + index
  );
};

export const generateBackwardArray = (lastElement, length) => {
  if (lastElement <= length) {
    // Handle special case where lastElement is less than 5
    return Array.from({ length: lastElement }, (_, index) => index + 1);
  } else {
    return Array.from(
      { length: length },
      (_, index) => lastElement - length + index + 1
    );
  }
};

export const generateBackwardArrayTrunc = (lastElement, length) => {
  if (lastElement <= length) {
    // Handle special case where lastElement is less than 5
    return Array.from({ length: length }, (_, index) => index + 1);
  } else {
    return Array.from(
      { length: length },
      (_, index) => lastElement - length + index + 1
    );
  }
};

export const getActivePages = (page, type, activePages, totalPages) => {
  switch (type) {
    case "last":
      return generateBackwardArray(totalPages, 5);
    case "first":
      return generateForwardArray(1, 5, totalPages);
    case "prev":
      return generateBackwardArray(activePages[4] - 1, 5);
    case "next":
      return generateBackwardArray(activePages[4] + 1, 5);
    case "trunc-next":
      return generateForwardArrayTrunc(page, 5, totalPages);
    case "trunc-prev":
      return generateBackwardArrayTrunc(page, 5);
    default:
      return activePages;
  }
};
