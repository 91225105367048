import React from "react";
import Box from "../Box";
import Image from "../Image";
import { EmptyPendingClaim } from "../../assets";
import Typography from "../Typography";

const EmptyClaimDataState = () => {
  return (
    <Box className="flex justify-center flex-col items-center">
      <Box className="w-full">
        <Image source={EmptyPendingClaim} className="w-full" />
      </Box>
      <Box className="flex flex-col gap-1 mt-4 text-center">
        <Typography className="text-base text-font-primary">
          No pending claims for approval
        </Typography>
        <Typography className="text-sm text-font-secondary">
          No claims await your approval at the moment. Relax and check back
          later.
        </Typography>
      </Box>
    </Box>
  );
};

export default EmptyClaimDataState;
