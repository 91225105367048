import React from "react";
import Modal from "../Modal";
import CalendarUI from "../Calendar";

const CalendarModal = ({ onClose, open, onDateSelect, value, type }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <CalendarUI
        onDateSelect={(data) => onDateSelect({ ...data, type })}
        value={value[type]}
        minDate={
          type === "dateEnd"
            ? value["dateStart"]
              ? new Date(value["dateStart"])
              : new Date()
            : ""
        }
      />
    </Modal>
  );
};

export default CalendarModal;
