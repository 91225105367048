import React from "react";
import Image from "../Image";
import { PowerWalletLogoLight } from "../../assets";

const Logo = () => {
  return (
    <Image
      source={PowerWalletLogoLight}
      alt="power-wallet-logo"
      className="w-4/5 pl-3"
    />
  );
};

export default Logo;
