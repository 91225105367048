import { LogLevel } from "@azure/msal-browser";
// console.log(window.location);
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MICROSOFT_AD_CLIENT_ID,
    authority:
      "https://login.microsoftonline.com/5238ef2c-0a39-412a-93fd-afee87838ce2",
      redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: ["User.Read"],
};

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
