import React from "react";
import { twMerge } from "../../utils";

// STYLES
const styles = {
  badge:
    "inline-flex items-center rounded-md  px-2 py-1 text-xs font-medium  ring-1 ring-inset capitalize",
};

const BadgeChip = ({ label, className }) => {
  return <div className={twMerge(styles.badge, className)}>{label}</div>;
};

export default BadgeChip;
