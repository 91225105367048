import Box from "../Box";
import Typography from "../Typography";
import CountBadge from "../CountBadge";

// STYLES
const styles = {
  expenseColumnWrapper: "flex items-center w-fit justify-between md:w-full md:justify-end md:gap-2",
};

const ExpenseColumn = ({ count, id }) => {
  return (
    <Box className={styles.expenseColumnWrapper}>
      {/* <Typography className="text-sm">{id}</Typography> */}
      <CountBadge count={count} />
    </Box>
  );
};

export default ExpenseColumn;
