import React from "react";
import Box from "../Box";
import Button from "../Button";
import Icon from "../Icon";
import Typography from "../Typography";
import { ChevronDownIcon } from "../../assets";
import { useClickAway } from "../../hooks";
import dayjs from "dayjs";
import CalendarUI from "../Calendar";

// STYLES
const styles = {
  filterWrapper: "relative inline-block text-left md:w-full",
  filterButton:
    "w-40 flex items-center gap-2 bg-white px-2 py-3 rounded ring-1 ring-slate-200 justify-between md:w-full",
  filterSelectDateButton:
    "w-fit flex items-center gap-2 bg-transparent px-2 py-3 rounded ring-1 ring-slate-200 justify-between cursor-default md:w-full",
  filterLabel: "text-font-secondary text-sm",
  filterDropdownContainer:
    "absolute flex flex-col right-0 z-20 mt-2 w-96 origin-top-right rounded bg-white drop-shadow p-2 md:left-0",
  activeFilterLabel: "text-font-primary text-sm",
};

const DashboardFilter = ({
  showAnalyticsFilter,
  onCloseDropDown,
  onDateSelect,
  onOpenFilterDropdown,
  filterValue,
}) => {
  return (
    <Box className="flex justify-end">
      <DateFilterWrapper onClickOutside={onCloseDropDown}>
        <Box className={styles.filterWrapper}>
          <Button
            className={styles.filterButton}
            onClick={() => onOpenFilterDropdown()}
          >
            <Typography component="span" className={styles.activeFilterLabel}>
              {dayjs(filterValue).format("MMM, YYYY")}
            </Typography>
            <Icon source={ChevronDownIcon} className="w-5" alt="pc-dropdown" />
          </Button>
          {showAnalyticsFilter && (
            <Box className={styles.filterDropdownContainer}>
              <CalendarUI
                onDateSelect={onDateSelect}
                value={filterValue}
                view="year"
              />
            </Box>
          )}
        </Box>
      </DateFilterWrapper>
    </Box>
  );
};

const DateFilterWrapper = ({ children, onClickOutside }) => {
  const ref = useClickAway(() => {
    onClickOutside();
  });
  return (
    <div className="md:w-full" ref={ref}>
      {children}
    </div>
  );
};

export default DashboardFilter;
