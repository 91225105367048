import React from "react";
import Box from "../Box";
import Typography from "../Typography";

const styles = {
  clarificationQuestionRibbon:
    "w-full p-3 py-2 bg-slate-100 text-left font-normal",
  clarificationAnswerRibbon:
    "flex flex-col gap-1 w-full p-3 py-2 bg-slate-200 text-left font-normal ",
  questionClarificationText: "text-xs text-font-secondary",
  clarificationLabel: "text-xs text-font-primary font-medium uppercase",
};

const ClarificationRibbon = ({ questions, clarifications, communications }) => {
  return (
    <>
      {communications.length > 0 &&
        communications.map((msg) => (
          <>
            {msg?.role.includes("user") ? (
              <Box className={styles.clarificationAnswerRibbon}>
                <Typography className={styles.clarificationLabel}>
                  Initiator:
                </Typography>

                <Typography className={styles.questionClarificationText}>
                  {msg?.description}
                </Typography>
              </Box>
            ) : (
              <Box className={styles.clarificationQuestionRibbon}>
                <Typography className={styles.clarificationLabel}>
                  {msg?.role.join(", ")}:
                </Typography>
                <Typography className={styles.questionClarificationText}>
                  {msg?.description}
                </Typography>
              </Box>
            )}
          </>
        ))}
    </>
  );
};

export default ClarificationRibbon;
