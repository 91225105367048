import React from "react";
import Box from "../Box";

// STYLES
const styles = {
  badge: "bg-secondary px-2 py-px text-xs text-font-light rounded ml-auto",
};

const SidebarBadge = ({ value }) => {
  return (
    <Box component="span" className={styles.badge}>
      {value}
    </Box>
  );
};

export default SidebarBadge;
