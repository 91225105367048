import { useEffect, useState } from "react";
import { PaginationLayout } from "./PaginationLayout";
import { generateForwardArray, getActivePages } from "./helper";
import Box from "../Box";

export const Pagination = ({
  isTableDataLoading,
  totalData,
  currentPage,
  rowsPerPage,
  totalPages,
  onPageChange,
}) => {
  const [activePages, setActivePages] = useState([]);
  let firstIndex = (currentPage - 1) * rowsPerPage + 1;
  let lastIndex =
    currentPage * rowsPerPage +
    (totalPages === currentPage ? (totalData % rowsPerPage) - rowsPerPage : 0);

  useEffect(() => {
    let paginationArray = generateForwardArray(1, 5, totalPages);
    setActivePages(paginationArray);
  }, [totalPages]); // eslint-disable-line

  const handlePageChange = (page, type) => {
    if (!activePages.includes(page)) {
      setActivePages(getActivePages(page, type, activePages, totalPages));
    }
    onPageChange(page);
  };

  return (
    <Box className="flex items-center justify-end mt-6 sm:flex-col sm:items-start sm:gap-2">
      <Box className="mr-6 text-xs text-font-primary">
        {firstIndex}-{lastIndex}
        {" of"} {totalData}
        {totalData === 1 ? " item" : " items"}
      </Box>
      <PaginationLayout
        onPageChange={handlePageChange}
        isTableDataLoading={isTableDataLoading}
        totalData={totalData}
        currentPage={currentPage}
        rowsPerPage={rowsPerPage}
        totalPages={totalPages}
        activePages={activePages}
      ></PaginationLayout>
    </Box>
  );
};
