import React from "react";
import Box from "../Box";
import Button from "../Button";
import Icon from "../Icon";
import Typography from "../Typography";
import DateFilterOption from "./DateFilterOption";
import { ChevronDownIcon } from "../../assets";
import { useClickAway } from "../../hooks";
import CustomDateFilter from "./CustomDateFilter";
import CalendarModal from "./CalendarModal";
import dayjs from "dayjs";

// STYLES
const styles = {
  filterWrapper: "relative inline-block text-left md:w-full",
  filterButton:
    "w-40 flex items-center gap-2 bg-white px-2 py-3 rounded ring-1 ring-slate-200 justify-between md:w-full",
  filterSelectDateButton:
    "w-fit flex items-center gap-2 bg-transparent px-2 py-3 rounded ring-1 ring-slate-200 justify-between cursor-default md:w-full",
  filterLabel: "text-font-secondary text-sm",
  filterDropdownContainer:
    "absolute flex flex-col right-0 z-20 mt-2 w-96 origin-top-right rounded bg-secondary-tinted px-6 py-4 pb-6 gap-4 sm:w-80 md:left-0 xs:w-72",
  activeFilterLabel: "text-font-primary text-sm",
};

const DateFilter = ({
  dateFilterData,
  onDateFilterSelection,
  dateAs,
  isCustom,
  customFilterDates,
  onCustomDatePickerSelect,
  onDateSelect,
  customDatePickerModal,
  showDateFilter,
  onDateFilterSelect,
  onApply,
  onCalendarModalClose,
}) => {
  const getByLabelText = (dateFilterData, dateAs) => {
    return dateFilterData.filter((day) => day.value === dateAs)[0].label;
  };
  return (
    <Box className="flex gap-4 md:flex-col-reverse">
      {customFilterDates.dateStart && customFilterDates.dateEnd && (
        <Button className={styles.filterSelectDateButton} disabled>
          <Typography component="span" className={styles.activeFilterLabel}>
            {dayjs(customFilterDates.dateStart).format("MMM D, YYYY")} -
            {dayjs(customFilterDates.dateEnd).format("MMM D, YYYY")}
          </Typography>
        </Button>
      )}
      <DateFilterWrapper onClickOutside={() => onDateFilterSelect(false)}>
        <Box className={styles.filterWrapper}>
          <Box className="flex items-center gap-4 md:w-full">
            <Button
              className={styles.filterButton}
              onClick={() => onDateFilterSelect(!showDateFilter)}
            >
              <Typography component="span" className={styles.activeFilterLabel}>
                {getByLabelText(dateFilterData, dateAs)}
              </Typography>
              <Icon
                source={ChevronDownIcon}
                className="w-5"
                alt="pc-dropdown"
              />
            </Button>
          </Box>
          {showDateFilter && (
            <Box className={styles.filterDropdownContainer}>
              <Typography className={styles.filterLabel}>Filter</Typography>
              {dateFilterData.map((filter) => (
                <DateFilterOption
                  activeFilter={isCustom ? "custom" : dateAs}
                  option={filter}
                  onFilterSelect={(filter) => onDateFilterSelection(filter)}
                />
              ))}
              {isCustom && (
                <Box>
                  <CalendarModal
                    onClose={onCalendarModalClose}
                    open={customDatePickerModal.open}
                    type={customDatePickerModal.type}
                    onDateSelect={onDateSelect}
                    value={customFilterDates}
                  />
                  <CustomDateFilter
                    customFilterDates={customFilterDates}
                    onDatePickerSelect={onCustomDatePickerSelect}
                    onApply={onApply}
                  />
                </Box>
              )}
            </Box>
          )}
        </Box>
      </DateFilterWrapper>
    </Box>
  );
};

const DateFilterWrapper = ({ children, onClickOutside }) => {
  const ref = useClickAway(() => {
    onClickOutside();
  });
  return (
    <div className="md:w-full" ref={ref}>
      {children}
    </div>
  );
};

export default DateFilter;
