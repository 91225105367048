import React from "react";
import Box from "../Box";
import Typography from "../Typography";

const styles = {
  labelTexts: "text-font-secondary text-sm",
  valueTexts: "text-font-primary text-sm",
};

const InvoiceBreakUp = ({ expenseList }) => {
  return (
    <Box className="mt-2 bg-slate-200">
      {expenseList?.length > 0 && expenseList.map((item) => (
        <Box className="p-4 flex justify-between border-b border-b-slate-300">
          <Box className="text-left flex-1">
            <Typography className={styles.labelTexts}>Category/GL</Typography>
            <Typography className={styles.valueTexts}>
              {`${item.categoryName}(${item?.category})`}
            </Typography>
          </Box>
          <Box className="text-center flex-1">
            <Typography className={styles.labelTexts}>Claim Amount</Typography>
            <Typography className={styles.valueTexts}>
              {"QAR"} {item.claimedAmount}
            </Typography>
          </Box>
          <Box className="text-right flex-1">
            <Typography className={styles.labelTexts}>Comment</Typography>
            <Typography className={styles.valueTexts}>
              {item?.comments || "-"}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default InvoiceBreakUp;
