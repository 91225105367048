import React, { Component } from "react";
import Button from "../Button";

export const PaginationButton = ({
  label,
  isActive,
  onClick,
  disabled,
  children,
}) => {
  return isActive ? (
    <Button
      className="w-8 h-8 ring-1 ring-secondary flex items-center justify-center rounded bg-secondary text-white"
      onClick={onClick}
    >
      {label || children}
    </Button>
  ) : (
    <Button
      className="w-8 h-8 ring-1 ring-secondary flex items-center justify-center rounded"
      disabled={disabled}
      onClick={onClick}
    >
      {label || children}
    </Button>
  );
};
