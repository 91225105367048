import { PaginationButton } from "./PaginationButton";
import Icon from "../Icon";
import {
  LeftArrowIcon,
  LeftArrowIconDisabled,
  LeftDoubleArrowIcon,
  LeftDoubleArrowIconDisabled,
  RightArrowIcon,
  RightArrowIconDisabled,
  RightDoubleArrowIcon,
  RightDoubleArrowIconDisabled,
} from "../../assets";
import Box from "../Box";
export const PaginationLayout = ({
  isTableDataLoading,
  totalData,
  currentPage,
  rowsPerPage,
  totalPages,
  onPageChange,
  activePages,
}) => {
  return (
    <Box className="flex gap-2 xs:flex-wrap">
      <Box className="flex gap-2">
        <PaginationButton
          onClick={() => onPageChange(1, "first")}
          disabled={currentPage === 1 || isTableDataLoading}
        >
          <Icon
            source={
              currentPage === 1
                ? LeftDoubleArrowIconDisabled
                : LeftDoubleArrowIcon
            }
            alt="left-double-icon"
          />
        </PaginationButton>
        <PaginationButton
          onClick={() => onPageChange(currentPage - 1, "prev")}
          disabled={currentPage === 1 || isTableDataLoading}
        >
          <Icon
            source={currentPage === 1 ? LeftArrowIconDisabled : LeftArrowIcon}
            alt="left-icon"
          />
        </PaginationButton>

        {!activePages.includes(1) && (
          <PaginationButton
            onClick={() => onPageChange(activePages[0] - 1, "trunc-prev")}
            disabled={isTableDataLoading}
          >
            <span>...</span>
          </PaginationButton>
        )}
      </Box>
      <Box className="flex gap-2 xs:justify-self-center">
        {activePages.map((pageNo, idx) => (
          <PaginationButton
            disabled={isTableDataLoading}
            key={idx}
            label={pageNo}
            isActive={currentPage === pageNo}
            onClick={() => onPageChange(pageNo)}
          />
        ))}
      </Box>
      <Box className="flex gap-2">
        {!activePages.includes(totalPages) && (
          <PaginationButton
            onClick={() => onPageChange(activePages[4] + 1, "trunc-next")}
            disabled={isTableDataLoading}
          >
            <span>...</span>
          </PaginationButton>
        )}
        <PaginationButton
          onClick={() => onPageChange(currentPage + 1, "next")}
          disabled={currentPage === totalPages || isTableDataLoading}
        >
          <Icon
            source={
              currentPage === totalPages
                ? RightArrowIconDisabled
                : RightArrowIcon
            }
            alt="right-icon"
          />
        </PaginationButton>
        <PaginationButton
          onClick={() => onPageChange(totalPages, "last")}
          disabled={currentPage === totalPages || isTableDataLoading}
        >
          <Icon
            source={
              currentPage === totalPages
                ? RightDoubleArrowIconDisabled
                : RightDoubleArrowIcon
            }
            alt="right-icon"
          />
        </PaginationButton>
      </Box>
    </Box>
  );
};
