import React from "react";
import { twMerge } from "../utils";

const Button = ({
  children,
  className,
  onClick,
  variant,
  disabled,
  ...props
}) => {
  const renderButtonComponent = (
    children,
    className,
    onClick,
    variant,
    ...props
  ) => {
    switch (variant) {
      case "filled-primary":
        return (
          <button
            disabled={disabled}
            className={twMerge(
              "bg-primary px-4 py-3 text-sm rounded-full ring-2 ring-primary text-white font-semibold tracking-wide disabled:opacity-60 disabled:cursor-not-allowed",
              className
            )}
            onClick={onClick}
            {...props}
          >
            {children}
          </button>
        );
      case "filled-secondary":
        return (
          <button
            disabled={disabled}
            className={twMerge(
              "bg-secondary px-4 py-3 text-sm rounded-full ring-2 ring-secondary text-white font-semibold tracking-wide disabled:opacity-60 disabled:cursor-not-allowed",
              className
            )}
            onClick={onClick}
            {...props}
          >
            {children}
          </button>
        );
        case "filled-critical":
          return (
            <button
              disabled={disabled}
              className={twMerge(
                "bg-red-600 px-4 py-3 text-sm rounded-full ring-2 ring-red-600 text-white font-semibold tracking-wide disabled:opacity-60 disabled:cursor-not-allowed",
                className
              )}
              onClick={onClick}
              {...props}
            >
              {children}
            </button>
          );
      case "outlined-primary":
        return (
          <button
            disabled={disabled}
            className={twMerge(
              "bg-white px-4 py-3 text-sm rounded-full ring-2 ring-primary text-primary font-semibold tracking-wide disabled:opacity-60 disabled:cursor-not-allowed",
              className
            )}
            onClick={onClick}
            {...props}
          >
            {children}
          </button>
        );
      case "outlined-secondary":
        return (
          <button
            disabled={disabled}
            className={twMerge(
              "bg-white px-4 py-3 text-sm rounded-full ring-2 ring-secondary text-secondary font-semibold tracking-wide disabled:opacity-60 disabled:cursor-not-allowed",
              className
            )}
            onClick={onClick}
            {...props}
          >
            {children}
          </button>
        );
      case "outlined-critical":
        return (
          <button
            disabled={disabled}
            className={twMerge(
              "bg-white px-4 py-3 text-sm rounded-full ring-2 ring-red-600 text-red-600 font-semibold tracking-wide disabled:opacity-60 disabled:cursor-not-allowed",
              className
            )}
            onClick={onClick}
            {...props}
          >
            {children}
          </button>
        );
      case "custom":
        return (
          <button
            disabled={disabled}
            className={twMerge(
              className,
              "disabled:opacity-60 disabled:cursor-not-allowed"
            )}
            onClick={onClick}
            {...props}
          >
            {children}
          </button>
        );
      default:
        return (
          <button
            disabled={disabled}
            className={twMerge(
              className,
              "disabled:opacity-60 disabled:cursor-not-allowed"
            )}
            onClick={onClick}
            {...props}
          >
            {children}
          </button>
        );
    }
  };
  return (
    <>
      {renderButtonComponent(children, className, onClick, variant, disabled)}
    </>
  );
};

export default Button;
