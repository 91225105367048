import React from "react";

const Box = ({ component, children, className, ...props }) => {
  const renderBoxComponent = () => {
    switch (component) {
      case "div":
        return (
          <div className={className} {...props}>
            {children}
          </div>
        );
      case "nav":
        return (
          <nav className={className} {...props}>
            {children}
          </nav>
        );
      case "aside":
        return (
          <aside className={className} {...props}>
            {children}
          </aside>
        );
      case "main":
        return (
          <main className={className} {...props}>
            {children}
          </main>
        );
      case "span":
        return (
          <span className={className} {...props}>
            {children}
          </span>
        );
      default:
        return (
          <div className={className} {...props}>
            {children}
          </div>
        );
    }
  };
  return <>{renderBoxComponent(component, children, className)}</>;
};

export default Box;
