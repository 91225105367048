import { EXPENSE_LIST, SSO_LOGIN, USERS_LIST, USER_INFO } from "./constants";
import { http } from "./httpServices";

export const getUserInfo = () => {
  return http.get(`${USER_INFO}`);
};

export const SSOLogin = (data) => {
  return http.post(`${SSO_LOGIN}`, data);
};

export const getUsersList = (page = 1, searchText = "", limit) => {
  return http.get(`${USERS_LIST}`, {
    params: {
      limit,
      page,
      searchText,
    },
  });
};
