import React from "react";
import Box from "../Box";
import Button from "../Button";
import Typography from "../Typography";
import { useUserContext } from "../../contexts/UserContext";

// STYLES
const styles = {
  bannerContainer:
    "p-4 bg-[#0A3A5D] flex flex-row justify-between items-center md:flex-col md:gap-4 md:items-start md:p-0 md:rounded-lg",
  bannerTextContainer: "w-3/4 md:w-full",
  bannerActionContainer:
    "flex justify-end w-1/4 md:p-4 md:pt-0 md:w-full md:justify-start",
  seeAllButton: "bg-rose-600 px-4 py-3 rounded text-white text-sm",
  bannerMainTextContainer: "md:p-4 md:bg-secondary md:rounded-t-lg",
  bannerMainText: "text-secondary md:text-white md:text-sm",
  bannerSecondaryText: "text-white md:p-4 md:pb-0 md:text-sm",
  countSpan: "text-rose-600 font-bold mx-1",
};

const SummaryBanner = ({ onSeeAllRequest, count = 0 }) => {
  const {
    user: { isManager },
  } = useUserContext();
  return (
    <Box className={styles.bannerContainer}>
      <Box className={styles.bannerTextContainer}>
        <Box className={styles.bannerMainTextContainer}>
          <Typography className={styles.bannerMainText}>
            Today’s Activity
          </Typography>
        </Box>
        <Box>
          <Typography className={styles.bannerSecondaryText}>
            You have a total of
            <Typography component="span" className={styles.countSpan}>
              {count}
            </Typography>
            new pending requests awaiting your approval or rejection.
          </Typography>
        </Box>
      </Box>
      {!isManager && (
        <Box className={styles.bannerActionContainer}>
          <Button className={styles.seeAllButton} onClick={onSeeAllRequest}>
            See All Requests
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default SummaryBanner;
