import React, { useEffect, useReducer, useState } from "react";
import Box from "../../components/Box";
import PageHeader from "../../components/ClaimDetails/PageHeader";
import ClaimDetailsPanelHeader from "../../components/ClaimDetails/ClaimDetailsPanelHeader";
import ClaimDetailsBody from "../../components/ClaimDetails/ClaimDetails";
import {
  ApproveClaimModal,
  ClarifyClaimModal,
  DeclineClaimModal,
} from "../../components/ClaimDetails/ClaimDetailsModal";
import {
  getCommunication,
  getExpenseDetails,
  getExpenseList,
  postCommunication,
  updateExpenseStatus,
  updateMultiExpenseStatus,
} from "../../services/expenseServices";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { DEFAULT_ERROR_MESSAGE } from "../../constants";
import { useLoadContext } from "../../contexts/LoaderContext";
// import BulkApproveConfirmationModal from "../../components/ClaimDetails/BulkApproveConfirmationModal";
import AcknowledgeModal from "../../components/ClaimDetails/AcknowledgeModal";
import StatusFilter from "../../components/ClaimDetails/StatusFilter";
import { getSettings } from "../../services/settingsServices";
import {
  CLAIM_ACTIONS,
  claimerDetailsReducer,
  claimsDetailsInitialState,
} from "./reducers";
import dayjs from "dayjs";
import ImageModal from "../../components/ClaimDetails/ImageModal";

const styles = {
  expenseContainer: "flex flex-col bg-white border border-slate-200",
};

const ClaimDetails = () => {
  const { id } = useParams();
  const [state, dispatch] = useReducer(
    claimerDetailsReducer,
    claimsDetailsInitialState
  );

  const {
    claimsList,
    expenseStatusList,
    queryParams: { status, dateAs, searchText, dateStart, dateEnd },
    popUps: { showDateFilter, customDatePickerModal },
    dateFilterList,
    isCustom,
    activeClaim,
    communication,
    isBatchSelected,
    clarifyComments,
    bulkComment,
  } = state;

  const [showInvoiceList, setInvoiceList] = useState(false);
  const [showApproveModal, setApproveModal] = useState(false);
  const [showDeclineModal, setDeclineModal] = useState(false);
  const [showClarifyModal, setClarifyModal] = useState(false);
  const [showAcknowledgeModal, setAcknowledgementModal] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const navigate = useNavigate();
  const { setLoader, loading } = useLoadContext();

  useEffect(() => {
    dispatch({ type: CLAIM_ACTIONS.SET_EMPLOYEE_ID, empId: id });
    fetchUserClaimDetails({
      ...claimsDetailsInitialState.queryParams,
      empId: id,
    });
    fetchSettings();
  }, []);

  const fetchUserClaimDetails = async (params) => {
    let postableQueries = JSON.parse(JSON.stringify(params));
    let { dateStart, dateEnd } = params;
    setLoader(true);
    postableQueries.dateStart = dateStart
      ? dayjs(dateStart).format("YYYY/MM/DD")
      : "";
    postableQueries.dateEnd = dateEnd
      ? dayjs(dateEnd).format("YYYY/MM/DD")
      : "";

    try {
      let res = await getExpenseList(postableQueries);
      let comRes;
      let detailsRes;
      if (res?.data?.body?.data[0]?._id) {
        comRes = await getCommunication(res?.data?.body?.data[0]?._id);
      }
      if (res?.data?.body?.data[0]?._id) {
        detailsRes = await getExpenseDetails(res?.data?.body?.data[0]?._id);
      }
      if (res.status >= 200) {
        dispatch({
          type: CLAIM_ACTIONS.SET_CLAIM_LIST,
          list: res?.data?.body?.data,
          activeClaim: detailsRes?.data?.body?.data,
          communication: comRes?.data?.body?.data,
        });
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.message || DEFAULT_ERROR_MESSAGE);
    } finally {
      setLoader(false);
    }
  };

  const fetchSettings = async () => {
    setLoader(true);
    try {
      let res = await getSettings();
      dispatch({
        type: CLAIM_ACTIONS.SET_EXPENSE_STATUS_LIST,
        list: res?.data?.body?.data?.expense_status,
      });
    } catch (err) {
      toast.error(err?.message || DEFAULT_ERROR_MESSAGE);
    } finally {
      setLoader(false);
    }
  };

  const handleSearch = (e) => {
    dispatch({
      type: CLAIM_ACTIONS.SET_SEARCH_FILTER,
      searchText: e.target.value,
    });
    fetchUserClaimDetails({
      ...state.queryParams,
      searchText: e.target.value,
    });
  };

  const handleCommentChange = (e) => {
    let val = e.target.value;
    let claimsList = state.claimsList;
    let activeClaim = state.activeClaim;

    let foundIndex = claimsList.findIndex(
      (list) => list._id == activeClaim._id
    );

    activeClaim["comments"] = val;
    claimsList[foundIndex]["comments"] = val;

    updatePostableField(activeClaim, claimsList);
  };

  const handleApprove = () => {
    setApproveModal(true);
  };
  const handleDecline = () => {
    setDeclineModal(true);
  };
  const handleClarify = () => {
    setClarifyModal(true);
  };

  const getPostableObject = (status, activeClaim) => {
    let {
      comments,
      isPhysicalCopyReceived,
      isAllAmountVerified,
      approvedAmount,
    } = activeClaim;
    return {
      status,
      comments: comments,
      isPhysicalCopyReceived: isPhysicalCopyReceived,
      isAllAmountVerified: isAllAmountVerified,
      approvedAmount: Number(approvedAmount),
    };
  };

  const getMultiPostableObject = (claimList) => {
    let formattedBulkClaimObj = claimList.map((data) => {
      return {
        comments: bulkComment,
        status: "success",
        expenseId: data?._id,
        approvedAmount: Number(data?.approvedAmount || 0),
        isPhysicalCopyReceived: activeClaim.isPhysicalCopyReceived,
        isAllAmountVerified: activeClaim.isAllAmountVerified,
      };
    });
    return formattedBulkClaimObj;
  };

  const handleApproveSubmit = async () => {
    setLoader(true);
    try {
      let res = await updateExpenseStatus(
        getPostableObject("success", activeClaim),
        activeClaim._id
      );
      if (res?.data?.success) {
        setApproveModal(false);
        // setComments("");
        toast.success("Approved Claim Request!");
        fetchUserClaimDetails({
          ...claimsDetailsInitialState.queryParams,
          status: status,
          empId: id,
        });
        // fetchClaimDetails();
        // fetchCommunications();
      }
    } catch (err) {
      toast.error(err.message || DEFAULT_ERROR_MESSAGE);
    } finally {
      setLoader(false);
    }
  };

  const handleApproveBulkSubmit = async () => {
    setLoader(true);
    try {
      let res = await updateMultiExpenseStatus(
        getMultiPostableObject(claimsList)
      );
      if (res?.data?.success) {
        setAcknowledgementModal(false);
        toast.success("Approved Bulk Claim Request!");
        setTimeout(() => navigate("/admin/claims"), 2000);

        // fetchUserClaimDetails({
        //   ...claimsDetailsInitialState.queryParams,
        //   empId: id,
        // });
      }
    } catch (err) {
      toast.error(err.message || DEFAULT_ERROR_MESSAGE);
    } finally {
      setLoader(false);
    }
  };

  const handleDeclineSubmit = async () => {
    setLoader(true);
    try {
      let res = await updateExpenseStatus(
        getPostableObject("rejected", activeClaim),
        activeClaim._id
      );
      if (res?.data?.success) {
        setDeclineModal(false);
        fetchUserClaimDetails({
          ...claimsDetailsInitialState.queryParams,
          status: status,
          empId: id,
        });
        // setComments("");
        toast.warning("Declined Claim Request!");
        // fetchClaimDetails();
        // fetchCommunications();
      }
    } catch (err) {
      toast.error(err.message || DEFAULT_ERROR_MESSAGE);
    } finally {
      setLoader(false);
    }
  };

  const handleClarifySubmit = async () => {
    setLoader(true);
    try {
      let res = await postCommunication({
        expenseId: activeClaim._id,
        comments: clarifyComments,
      });
      if (res?.data?.success) {
        setClarifyModal(false);
        fetchUserClaimDetails({
          ...claimsDetailsInitialState.queryParams,
          status: status,
          empId: id,
        });
        dispatch({
          type: CLAIM_ACTIONS.SET_CLARIFY_COMMENT,
          clarifyComments: "",
        });
        toast.warning("Clarification Query Sent!");
        // fetchClaimDetails();
        // fetchCommunications();
      }
    } catch (err) {
      toast.error(err.message || DEFAULT_ERROR_MESSAGE);
    } finally {
      setLoader(false);
    }
  };

  const handleClarifyCommentChange = (e) => {
    dispatch({
      type: CLAIM_ACTIONS.SET_CLARIFY_COMMENT,
      clarifyComments: e.target.value,
    });
    // setClarifyComments(e.target.value);
  };

  const handleAcknowledgementChange = (val, name) => {
    let claimsList = state.claimsList;
    let activeClaim = state.activeClaim;

    let foundIndex = claimsList.findIndex(
      (list) => list._id == activeClaim._id
    );

    activeClaim[name] = val;
    claimsList[foundIndex][name] = val;

    updatePostableField(activeClaim, claimsList);
  };

  const handleStatusFilterSelect = (filterValue) => {
    dispatch({
      type: CLAIM_ACTIONS.SET_STATUS_FILTER,
      status: filterValue,
    });
    fetchUserClaimDetails({
      ...state.queryParams,
      status: filterValue,
    });
  };

  const handleDateFilterSelection = (filter) => {
    if (filter.value === "custom") {
      dispatch({
        type: CLAIM_ACTIONS.TOGGLE_CUSTOM_DATE_FLAG,
        isCustom: true,
      });
    } else {
      dispatch({
        type: CLAIM_ACTIONS.SET_DATE_FILTER,
        dateAs: filter.value,
        isCustom: false,
        showDateFilter: false,
        dateEnd: "",
        dateStart: "",
      });
      fetchUserClaimDetails({
        ...state.queryParams,
        dateAs: filter.value,
        dateEnd: "",
        dateStart: "",
      });
      // console.log(filter);
      // setIfShowDateFilter(false);
      // setIfCustom(false);

      // setPage(INTIAL_PAGE);
      // setDateFilter(filter.label);
      // fetchClaimList(
      //   INTIAL_PAGE,
      //   activeStatusFilter,
      //   filterObj?.filter,
      //   "",
      //   "",
      //   searchText
      // );
    }
  };

  const handleCustomDatePickerSelect = (type) => {
    dispatch({
      type: CLAIM_ACTIONS.SET_CUSTOM_DATEPICKER_MODAL,
      open: true,
      valueType: type,
    });
    // setCustomDatePickerModal({ open: true, type });
  };

  const handleCustomDateSelect = (data) => {
    dispatch({
      type: CLAIM_ACTIONS.SET_CUSTOM_DATE,
      open: false,
      valueType: "",
      dateType: data.type,
      value: data.value,
    });
    // setCustomFilterDates({ ...customFilterDates, [data.type]: data.value });
    // setCustomDatePickerModal({ open: false, type: "" });
  };

  const handleApply = () => {
    dispatch({
      type: CLAIM_ACTIONS.SET_DATE_FILTER,
      dateAs: "custom",
      isCustom: true,
      showDateFilter: false,
      dateEnd: state.queryParams.dateEnd,
      dateStart: state.queryParams.dateStart,
    });
    fetchUserClaimDetails({
      ...state.queryParams,
      dateAs: "",
      dateEnd: state.queryParams.dateEnd,
      dateStart: state.queryParams.dateStart,
    });
  };

  const handleClaimSelect = async (claim) => {
    setLoader(true);
    try {
      let activeClaim = claimsList.filter((list) => list._id === claim._id)[0];
      if (activeClaim) {
        let comRes = await getCommunication(activeClaim._id);
        let detailsRes = await getExpenseDetails(activeClaim._id);

        dispatch({
          type: CLAIM_ACTIONS.SET_ACTIVE_CLAIM,
          activeClaim: detailsRes?.data?.body?.data,
          communication: comRes?.data?.body?.data,
        });
      }
    } catch (err) {
      toast.error(err?.message || DEFAULT_ERROR_MESSAGE);
    } finally {
      setLoader(false);
    }
  };

  const updatePostableField = (activeClaim, claimsList) => {
    dispatch({
      type: CLAIM_ACTIONS.UPDATE_POSTABLE_FIELDS,
      activeClaim,
      claimsList,
    });
  };

  const handleApprovedAmountChange = (e) => {
    let val = e.target.value;
    let claimsList = state.claimsList;
    let activeClaim = state.activeClaim;

    let foundIndex = claimsList.findIndex(
      (list) => list._id == activeClaim._id
    );

    activeClaim["approvedAmount"] = val;
    claimsList[foundIndex]["approvedAmount"] = val;

    updatePostableField(activeClaim, claimsList);
  };

  const handleBatchSelect = (selected) => {
    dispatch({
      type: CLAIM_ACTIONS.SET_BATCH_SELECT,
      isBatchSelected: selected,
    });
  };

  const handleViewImage = (img) => {
    setPreviewImage(img);
  };

  const handleBulkCommentChange = (e) => {
    dispatch({
      type: CLAIM_ACTIONS.SET_BULK_COMMENT,
      bulkComment: e.target.value,
    });
  };

  return (
    <Box className="p-8 md:p-4">
      <PageHeader
        name={activeClaim?.userInfo?.name}
        customFilterDates={{ dateEnd, dateStart }}
        isCustom={isCustom}
        dateAs={dateAs}
        showDateFilter={showDateFilter}
        dateFilterData={dateFilterList}
        onDateFilterSelection={handleDateFilterSelection}
        onCustomDatePickerSelect={handleCustomDatePickerSelect}
        onDateSelect={handleCustomDateSelect}
        customDatePickerModal={customDatePickerModal}
        onDateFilterSelect={(val) => {
          if (!customDatePickerModal.open) {
            // setIfShowDateFilter(val);
            dispatch({
              type: CLAIM_ACTIONS.TOGGLE_SHOW_DATE_FILTER,
              showDateFilter: val,
            });
          }
        }}
        onApply={handleApply}
        onCalendarModalClose={() =>
          // setCustomDatePickerModal({ open: false, type: "" })
          dispatch({
            type: CLAIM_ACTIONS.SET_CUSTOM_DATEPICKER_MODAL,
            open: false,
            valueType: "",
          })
        }
        onClaimRequestClick={() => navigate("/admin/claims")}
      />
      <StatusFilter
        onSelectStatusFilter={handleStatusFilterSelect}
        active={status}
        filterOptions={expenseStatusList}
      />
      <Box className={styles.expenseContainer}>
        <ClaimDetailsPanelHeader
          activeInvoiceNumber={activeClaim?.invoiceNo}
          totalInvoiceCount={claimsList?.length}
          claimantName={activeClaim?.userInfo?.name}
          claimantCompany={activeClaim?.userInfo?.companyName}
          showInvoiceList={showInvoiceList}
          onToggleInvoiceList={() => setInvoiceList(!showInvoiceList)}
          noPendingClaims={claimsList.length < 1}
        />
        <ClaimDetailsBody
          activeStatus={status}
          activeClaim={activeClaim}
          claimsList={claimsList}
          onCommentChange={handleCommentChange}
          onApprove={handleApprove}
          onClarify={handleClarify}
          onDecline={handleDecline}
          onSearch={handleSearch}
          searchText={searchText}
          showInvoiceList={showInvoiceList}
          communication={communication}
          isBatchSelected={isBatchSelected}
          onBatchSelectToggle={handleBatchSelect}
          onAcknowledgementChange={handleAcknowledgementChange}
          // isInvoiceAcknowledged={acknowledgement.isInvoiceAcknowledged}
          // isStatementAcknowledged={acknowledgement.isStatementAcknowledged}
          onApproveAll={() => setAcknowledgementModal(true)}
          noPendingClaims={claimsList.length < 1}
          onClaimClick={handleClaimSelect}
          onApprovedAmountChange={handleApprovedAmountChange}
          onViewImg={handleViewImage}
        />
        <ApproveClaimModal
          loading={loading}
          showApproveModal={showApproveModal}
          onCloseApprove={() => setApproveModal(false)}
          onSubmitApprove={handleApproveSubmit}
        />
        <DeclineClaimModal
          loading={loading}
          showDeclineModal={showDeclineModal}
          onCloseDecline={() => setDeclineModal(false)}
          onSubmitDecline={handleDeclineSubmit}
        />
        <ClarifyClaimModal
          loading={loading}
          showClarifyModal={showClarifyModal}
          onCloseClarify={() => setClarifyModal(false)}
          onSubmitClarify={handleClarifySubmit}
          onClarifyCommentChange={handleClarifyCommentChange}
          clarifyComments={clarifyComments}
        />
        {/* <BulkApproveConfirmationModal
          onCloseBulkApprove={() => console.log()}
          onContinue={() => console.log()}
          showBulkApproveModal={false}
        /> */}
        <AcknowledgeModal
          loading={loading}
          onClose={() => setAcknowledgementModal(false)}
          onContinue={handleApproveBulkSubmit}
          showAcknowledgeModal={showAcknowledgeModal}
          onAcknowledgementChange={handleAcknowledgementChange}
          isPhysicalCopyReceived={activeClaim.isPhysicalCopyReceived}
          isAllAmountVerified={activeClaim.isAllAmountVerified}
          bulkComment={bulkComment}
          onCommentChange={handleBulkCommentChange}
        />
        <ImageModal imgUrl={previewImage} onClose={() => setPreviewImage("")} />
      </Box>
    </Box>
  );
};

export default ClaimDetails;
