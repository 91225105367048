import React from "react";
import { Outlet } from "react-router-dom";
import Box from "../components/Box";
import { AuthLayoutBackground } from "../assets";

// STYLES
const styles = {
  authLayoutContainer: "h-screen w-screen bg-no-repeat bg-cover flex",
  authLayoutWrapper: "h-full w-full bg-primary/80 flex items-center justify-center",
  authLayoutContainerBg: { backgroundImage: `url(${AuthLayoutBackground})` },
};

const AuthenticationLayout = () => {
  return (
    <Box
      component="main"
      className={styles.authLayoutContainer}
      style={styles.authLayoutContainerBg}
    >
      <Box className={styles.authLayoutWrapper}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default AuthenticationLayout;
