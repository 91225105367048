import React from "react";
import { NotificationIcon } from "../../assets";
import Box from "../Box";
import Icon from "../Icon";
import NotificationData from "./NotificationData";
import { useClickAway } from "../../hooks";

const Notification = ({
  showNotification,
  onCloseDropDown,
  onClickNotification,
}) => {
  // STYLES
  const styles = {
    notificationContainer:
      "flex h-10 w-10 bg-gray-100 rounded-full items-center justify-center border border-slate-200 relative cursor-pointer",
    newNotification:
      "h-3 w-3 bg-red-500 rounded-full absolute top-0 right-0 border-2 border-white",
    notificationDropdownContainer:
      "absolute flex flex-col right-0 z-20 top-12 w-96 origin-top-right rounded bg-white drop-shadow p-2 lg:-right-12 sm:w-80 xs:w-72",
  };
  return (
    <NotificationWrapper onClickOutside={onCloseDropDown}>
      <Box
        className={styles.notificationContainer}
        onClick={onClickNotification}
      >
        <Icon className="h-6 w-6" source={NotificationIcon} alt="" />
        {/* <Box component="span" className={styles.newNotification} /> */}
        {showNotification && (
          <Box className={styles.notificationDropdownContainer}>
            <NotificationData />
          </Box>
        )}
      </Box>
    </NotificationWrapper>
  );
};

const NotificationWrapper = ({ children, onClickOutside }) => {
  const ref = useClickAway(() => {
    onClickOutside();
  });
  return (
    <div className="md:w-full mt-2" ref={ref}>
      {children}
    </div>
  );
};

export default Notification;
