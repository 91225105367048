import React from "react";
import Box from "../Box";
import Logo from "./Logo";
import SidebarList from "./SidebarList";
import LogoutButton from "./LogoutButton";
import { twMerge } from "../../utils";

// STYLES
const styles = {
  sidebarContainer:
    "flex flex-col h-screen w-80 bg-primary px-6 py-8 z-40 min-h-fit overflow-auto xl:w-64 lg:w-80",
  sidebarOverlay:
    "w-80 xl:w-64 h-screen lg:w-screen bg-black/50 z-30 lg:overflow-hidden lg:absolute ",
};
const SideBar = ({ onLogout, items, activePath, onNavigate, sidebarOpen }) => {
  return (
    <Box
      className={twMerge(
        styles.sidebarOverlay,
        `lg:${sidebarOpen ? "block" : "hidden"}`
      )}
    >
      <Box
        component="aside"
        className={twMerge(
          styles.sidebarContainer,
          `lg:${sidebarOpen ? "flex" : "hidden"}`
        )}
      >
        <Logo />
        <SidebarList
          items={items}
          activePath={activePath}
          onNavigate={onNavigate}
        />
        <LogoutButton onLogout={onLogout} />
      </Box>
    </Box>
  );
};

export default SideBar;
