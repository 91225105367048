import dayjs from "dayjs";

export const getFormattedClaimList = (claimData) => {
  let formattedClaimList = claimData.map((data) => {
    return {
      name: data?.userInfo?.name,
      company: data?.outletInfo?.name,
      expenseNo: data?.requestNo,
      expenseCount: "1", // NEED REVISIT
      requestedDate: dayjs(data.createdAt).format("MMM D, YYYY"),
      category: data?.categoryName,
      currency: data?.currency,
      amount: data?.amount,
      isNew: true,
      status: data?.status,
      id: data?._id,
    };
  });
  return formattedClaimList;
};

export const getFormattedClaimersList = (claimersData) => {
  let formattedClaimList = claimersData.map((data) => {
    return {
      name: data?.name,
      company: data?.companyName,
      employeeId: data?.empId,
      code: data?.outletCode,
      pendingCount: data?.pending_count,
      currency: "QAR",
      amount: data?.pending_amount,
      isNew: true,
      id: data?._id,
    };
  });
  return formattedClaimList;
};
