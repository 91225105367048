import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router";
import { MicrosoftLogo, PowerWalletBlueLogo } from "../assets";
import { loginRequest } from "../authConfig";
import Box from "../components/Box";
import Button from "../components/Button";
import Icon from "../components/Icon";
import Typography from "../components/Typography";
import { toast } from "react-toastify";
import { SSOLogin } from "../services/userService";
import { useLoadContext } from "../contexts/LoaderContext";
import { useUserContext } from "../contexts/UserContext";
import { hasApproverRole } from "../helper";

const styles = {
  mainClassContainer:
    "flex py-10 px-8 w-96 flex-col items-center bg-black/40 rounded-lg sm:w-80",
  loginDetailText: "mt-5 text-white text-base font-normal text-center",
};

const Login = () => {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const { setLoader } = useLoadContext();
  const { user, setUser } = useUserContext();

  const handleLogin = async () => {
    setLoader(true);
    try {
      const { accessToken, idToken } = await instance.loginPopup();
      let apiResponse = await SSOLogin({ accessToken, idToken });
      if (
        apiResponse?.data?.body?.role?.length === 1 &&
        apiResponse?.data?.body?.role.includes("user")
      ) {
        toast.error("Unauthorized!");
      } else {
        localStorage.setItem(
          "powerwallet-token",
          apiResponse?.data?.body?.token
        );
        localStorage.setItem(
          "powerwallet-role",
          JSON.stringify(apiResponse?.data?.body?.role)
        );
        let isManager = apiResponse?.data?.body?.role.includes("manager");
        let isApprover = hasApproverRole(apiResponse?.data?.body?.role);
        localStorage.setItem("powerwallet-manager", JSON.stringify(isManager));
        localStorage.setItem("powerwallet-approver", JSON.stringify(isApprover));
   
        setUser({
          ...user,
          isManager,
          isApprover,
          roles: apiResponse?.data?.body?.role,
        });
        navigate("/admin/");
      }
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoader(false);
    }
  };
  return (
    <Box className={styles.mainClassContainer}>
      <Icon
        className="w-40"
        source={PowerWalletBlueLogo}
        alt="power-wallet-blue"
      />
      <Icon
        className="w-14 mt-10"
        source={MicrosoftLogo}
        alt="power-wallet-blue"
      />
      <Typography className={styles.loginDetailText}>
        Login with Company Email ID
      </Typography>
      <Box className="mt-6 w-full px-4">
        <Button
          variant="filled-secondary"
          className="w-full"
          onClick={handleLogin}
        >
          LOGIN
        </Button>
      </Box>
    </Box>
  );
};

export default Login;
