import React from "react";
import Box from "../Box";
import Typography from "../Typography";
import Button from "../Button";

// STYLES
const styles = {
  pageHeaderWrapper:
    "flex justify-between items-center pb-4 md:flex-col md:gap-4 md:items-start",
  pageHeaderText: "text-xl font-bold text-font-primary",
};

const PageHeader = () => {
  return (
    <Box className={styles.pageHeaderWrapper}>
      <Typography className={styles.pageHeaderText}>Claim Requests</Typography>
    </Box>
  );
};

export default PageHeader;
