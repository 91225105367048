import { CLAIM_REQUEST_DATE_FILTER } from "../../constants";

export const CLAIM_ACTIONS = {
  SET_EMPLOYEE_ID: "set_employee_id",
  SET_STATUS_FILTER: "set_status_filter",
  SET_CLAIM_LIST: "set_claim_list",
  SET_EXPENSE_STATUS_LIST: "set_expense_status_list",
  SET_DATE_FILTER: "set_date_filter",
  SET_SEARCH_FILTER: "set_search_filter",
  TOGGLE_CUSTOM_DATE_FLAG: "toggle_custom_date_flag",
  SET_CUSTOM_DATEPICKER_MODAL: "set_custom_datepicker_modal",
  SET_CUSTOM_DATE: "set_custom_date",
  SET_ACTIVE_CLAIM: "set_active_claim",
  SET_COMMUNICATION_DATA: "set_communication_date",
  UPDATE_POSTABLE_FIELDS: "set_acknowledgement",
  SET_APPROVED_AMOUNT: "set_approved_amount",
  SET_BATCH_SELECT: "set_batch_select",
  SET_CLARIFY_COMMENT: "set_clarify_comment",
  SET_BULK_COMMENT: "set_bulk_comment",
};

export const claimsDetailsInitialState = {
  queryParams: {
    page: 1,
    status: "all",
    dateAs: CLAIM_REQUEST_DATE_FILTER[0].value,
    dateStart: "",
    limit: 100,
    dateEnd: "",
    searchText: "",
    empId: "",
  },
  popUps: {
    showDateFilter: false,
    customDatePickerModal: {
      open: false,
      type: "",
    },
  },
  claimsList: [],
  expenseStatusList: [],
  dateFilterList: CLAIM_REQUEST_DATE_FILTER,
  isCustom: false,
  activeClaim: {
    isPhysicalCopyReceived: false,
    isAllAmountVerified: false,
    approvedAmount: 0,
  },
  communication: [],
  isBatchSelected: false,
  clarifyComments: "",
  bulkComment: "",
};

export const claimerDetailsReducer = (state, action) => {
  switch (action.type) {
    case CLAIM_ACTIONS.SET_EMPLOYEE_ID: {
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          empId: action.empId,
        },
      };
    }
    case CLAIM_ACTIONS.SET_CUSTOM_DATE: {
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          [action.dateType]: [action.value],
        },
        popUps: {
          ...state.popUps,
          customDatePickerModal: {
            open: action.open,
            type: action.valueType,
          },
        },
      };
    }
    case CLAIM_ACTIONS.SET_CUSTOM_DATEPICKER_MODAL: {
      return {
        ...state,
        popUps: {
          ...state.popUps,
          customDatePickerModal: {
            open: action.open,
            type: action.valueType,
          },
        },
      };
    }
    case CLAIM_ACTIONS.SET_SEARCH_FILTER: {
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          searchText: action.searchText,
        },
      };
    }
    case CLAIM_ACTIONS.SET_STATUS_FILTER: {
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          status: action.status,
        },
        isBatchSelected: false,
      };
    }
    case CLAIM_ACTIONS.SET_DATE_FILTER: {
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          dateAs: action.dateAs,
          dateEnd: action.dateEnd,
          dateStart: action.dateStart,
        },
        popUps: {
          ...state.popUps,
          showDateFilter: action.showDateFilter,
        },
        isCustom: action.isCustom,
      };
    }
    case CLAIM_ACTIONS.SET_CLAIM_LIST: {
      return {
        ...state,
        claimsList: [...action.list].map((v) => ({
          ...v,
          approvedAmount: v.approvedAmount || v.totalClaimedAmount,
        })),
        activeClaim: {
          ...state.activeClaim,
          ...action.activeClaim,
          approvedAmount:
            action?.activeClaim?.approvedAmount ||
            action?.activeClaim?.totalClaimedAmount ||
            0,
        },
        communication: action.communication,
      };
    }
    case CLAIM_ACTIONS.SET_EXPENSE_STATUS_LIST: {
      return {
        ...state,
        expenseStatusList: [...action.list],
      };
    }
    case CLAIM_ACTIONS.TOGGLE_CUSTOM_DATE_FLAG: {
      return {
        ...state,
        isCustom: action.isCustom,
      };
    }
    case CLAIM_ACTIONS.TOGGLE_SHOW_DATE_FILTER: {
      return {
        ...state,
        popUps: {
          ...state.popUps,
          showDateFilter: action.showDateFilter,
        },
      };
    }
    case CLAIM_ACTIONS.SET_ACTIVE_CLAIM: {
      return {
        ...state,
        activeClaim: action.activeClaim,
        communication: action.communication,
      };
    }
    case CLAIM_ACTIONS.UPDATE_POSTABLE_FIELDS: {
      return {
        ...state,
        activeClaim: action.activeClaim,
        claimsList: [...action.claimsList],
      };
    }
    case CLAIM_ACTIONS.SET_BATCH_SELECT: {
      return {
        ...state,
        isBatchSelected: action.isBatchSelected,
      };
    }
    case CLAIM_ACTIONS.SET_BULK_COMMENT: {
      return {
        ...state,
        bulkComment: action.bulkComment,
      };
    }
    case CLAIM_ACTIONS.SET_CLARIFY_COMMENT: {
      return {
        ...state,
        clarifyComments: action.clarifyComments,
      };
    }
  }
};
