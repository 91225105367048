import { createContext, useContext } from "react";

export const UserContext = createContext({
  user: {
    isManager: false,
    isApprover: false,
    roles: [],
  }, // set a default value
  setUser: () => {},
});

export const useUserContext = () => useContext(UserContext);
