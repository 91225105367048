import {
  EXPENSE_LIST,
  APPROVE,
  COMMUNICATION,
  GET_COMMUNICATION,
  UPDATE_EXPENSE_STATUS,
  GET_EXPENSE,
  UPDATE_MULTI_EXPENSE_STATUS,
  DOWNLOAD_REPORTS,
} from "./constants";
import { http } from "./httpServices";

export const getExpenseList = (params) => {
  return http.get(`${EXPENSE_LIST}${APPROVE}`, {
    params,
  });
};

export const getCommunication = (expenseId) => {
  return http.get(`${GET_COMMUNICATION}`, {
    params: {
      expenseId,
    },
  });
};

export const postCommunication = (data) => {
  return http.post(`${COMMUNICATION}`, data);
};

export const updateExpenseStatus = (data, id) => {
  return http.put(`${UPDATE_EXPENSE_STATUS}/${id}`, data);
};

export const updateMultiExpenseStatus = (data) => {
  return http.post(`${UPDATE_MULTI_EXPENSE_STATUS}`, data);
};

export const getExpenseDetails = (id) => {
  return http.get(`${GET_EXPENSE}/${id}`);
};

export const getReports = (params) => {
  return http.get(`${DOWNLOAD_REPORTS}`, {
    params: { ...params, download: "false" },
  });
};

export const downloadReports = (params) => {
  return http.get(`${DOWNLOAD_REPORTS}`, {
    responseType: "blob",
    params: { ...params, download: "true" },
  });
};
