import React from "react";
import Box from "../Box";
import Button from "../Button";
import Modal from "../Modal";
import Checkbox from "../Checkbox";
import Image from "../Image";

const styles = {
  modalContainer: "flex justify-center flex-col items-center py-2 w-fit h-fit overflow-y-auto",
  actionContainer:
    "flex my-2 mr-1 gap-4 justify-end flex-row items-center w-full",
  buttonStyle: "w-40",
  clarifyText: "mt-2 mb-2 text-slate-600 text-xs self-start w-60",
  invoicePreview: "w-full",
};

const ImageModal = ({ imgUrl, onClose }) => {
  return (
    <Modal open={!!imgUrl} onClose={onClose}>
      <Box className={styles.modalContainer}>
        <Box className={styles.actionContainer}>
          <Button
            className={styles.buttonStyle}
            variant="outlined-primary"
            onClick={onClose}
          >
            Close
          </Button>
        </Box>
        <Image source={imgUrl} className={styles.invoicePreview} />
      </Box>
    </Modal>
  );
};

export default ImageModal;
