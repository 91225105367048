import { useNavigate } from "react-router";
import Box from "../components/Box";
import Approvals from "../components/Dashboard/Approvals";
import Categories from "../components/Dashboard/Categories";
import RequestAnalytics from "../components/Dashboard/RequestAnalytics";
import SummaryBanner from "../components/Dashboard/SummaryBanner";
import TotalRequestsCount from "../components/Dashboard/TotalRequestsCount";
import { useEffect, useState } from "react";
import { getAnalytics } from "../services/analyticsService";
import { toast } from "react-toastify";
import { DEFAULT_ERROR_MESSAGE } from "../constants";
import { useLoadContext } from "../contexts/LoaderContext";
import DashboardFilter from "../components/Dashboard/DashboardFilter";
import dayjs from "dayjs";

// STYLES
const styles = {
  dashboardPageContainer: "p-8 md:p-4 flex flex-col gap-4 md:gap-8",
  approvalCategoryWrapper: "flex flex-row gap-4 md:gap-8 md:flex-col",
};

const Dashboard = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [date, setDate] = useState("");
  const { setLoader } = useLoadContext();
  const [showAnalyticsFilter, setShowAnayticsFilter] = useState(false);

  const handleSeeAllRequests = () => {
    navigate(`/admin/claims`);
  };

  useEffect(() => {
    setDate(new Date());
    fetchAnalytics(new Date());
  }, []);

  const fetchAnalytics = async (date) => {
    let month = dayjs(date).format("MM");
    let year = dayjs(date).format("YYYY");
    setLoader(true);
    try {
      let res = await getAnalytics(month, year);
      setData(res?.data?.body);
    } catch (err) {
      toast.error(err?.message || DEFAULT_ERROR_MESSAGE);
    } finally {
      setLoader(false);
    }
  };

  const handleDateSelect = (e) => {
    setDate(new Date(e.value));
    setShowAnayticsFilter(false);
    fetchAnalytics(new Date(e.value));
  };

  return (
    <Box className={styles.dashboardPageContainer}>
      <Box>
        <DashboardFilter
          filterValue={date}
          showAnalyticsFilter={showAnalyticsFilter}
          onCloseDropDown={() => setShowAnayticsFilter(false)}
          onOpenFilterDropdown={() => setShowAnayticsFilter(true)}
          onDateSelect={handleDateSelect}
        />
      </Box>
      <SummaryBanner
        onSeeAllRequest={handleSeeAllRequests}
        count={data?.chartData?.pendingCount}
      />
      {data?.monthlyReport && <RequestAnalytics data={data?.monthlyReport} />}
      <TotalRequestsCount data={data?.chartData} />
      <Box className={styles.approvalCategoryWrapper}>
        {data?.chartData && <Approvals data={data?.chartData} />}
        {data?.category && <Categories data={data?.category} />}
      </Box>
    </Box>
  );
};

export default Dashboard;
