export const getIcon = (selected) => {
  return selected ? (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.616 1.66602H6.39102C3.56602 1.66602 1.66602 3.64935 1.66602 6.59935V13.4077C1.66602 16.3493 3.56602 18.3327 6.39102 18.3327H13.616C16.441 18.3327 18.3327 16.3493 18.3327 13.4077V6.59935C18.3327 3.64935 16.441 1.66602 13.616 1.66602Z"
        fill="#0A3A5D"
      />
      <path
        d="M9.00982 12.7067C8.82315 12.7067 8.63648 12.6359 8.49398 12.4934L6.51648 10.5159C6.23148 10.2309 6.23148 9.76922 6.51648 9.48505C6.80148 9.20005 7.26232 9.19922 7.54732 9.48422L9.00982 10.9467L12.4498 7.50672C12.7348 7.22172 13.1957 7.22172 13.4807 7.50672C13.7657 7.79172 13.7657 8.25339 13.4807 8.53839L9.52565 12.4934C9.38315 12.6359 9.19648 12.7067 9.00982 12.7067Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_3551_3812" fill="white">
        <path d="M13.616 1.66602H6.39102C3.56602 1.66602 1.66602 3.64935 1.66602 6.59935V13.4077C1.66602 16.3493 3.56602 18.3327 6.39102 18.3327H13.616C16.441 18.3327 18.3327 16.3493 18.3327 13.4077V6.59935C18.3327 3.64935 16.441 1.66602 13.616 1.66602Z" />
      </mask>
      <path
        d="M13.616 0.166016H6.39102V3.16602H13.616V0.166016ZM6.39102 0.166016C4.61434 0.166016 3.02654 0.797984 1.88589 1.98886C0.750605 3.17412 0.166016 4.7992 0.166016 6.59935H3.16602C3.16602 5.4495 3.53143 4.60791 4.05239 4.06401C4.56799 3.52571 5.34269 3.16602 6.39102 3.16602V0.166016ZM0.166016 6.59935V13.4077H3.16602V6.59935H0.166016ZM0.166016 13.4077C0.166016 15.2047 0.75128 16.8278 1.88651 18.0115C3.02699 19.2008 4.61441 19.8327 6.39102 19.8327V16.8327C5.34262 16.8327 4.56754 16.4729 4.05177 15.9351C3.53075 15.3918 3.16602 14.5523 3.16602 13.4077H0.166016ZM6.39102 19.8327H13.616V16.8327H6.39102V19.8327ZM13.616 19.8327C15.3929 19.8327 16.979 19.2005 18.1177 18.0102C19.2507 16.8257 19.8327 15.2029 19.8327 13.4077H16.8327C16.8327 14.5541 16.4689 15.3938 15.9498 15.9364C15.4364 16.4732 14.6641 16.8327 13.616 16.8327V19.8327ZM19.8327 13.4077V6.59935H16.8327V13.4077H19.8327ZM19.8327 6.59935C19.8327 4.80102 19.2513 3.17613 18.1183 1.99022C16.9794 0.798235 15.393 0.166016 13.616 0.166016V3.16602C14.6641 3.16602 15.4359 3.52546 15.9492 4.06264C16.4682 4.6059 16.8327 5.44767 16.8327 6.59935H19.8327Z"
        fill="#0A3A5D"
        mask="url(#path-1-inside-1_3551_3812)"
      />
    </svg>
  );
};
