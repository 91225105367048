import React from "react";
import Textarea from "../Textarea";
import Box from "../Box";
import Typography from "../Typography";

const styles = {
  gmClarificationAnswerRibbon:
    "flex flex-col mt-4 w-full p-3 bg-slate-200 text-left font-normal gap-4",
  gmQueryClarificationLabel: "text-xs text-font-primary font-medium",
  gmQueryClarificationValue: "text-xs text-font-secondary",
  gmCommentLabel: "text-font-secondary text-sm mt-4",
  gmCommentValue: "text-font-primary text-base",
  gmCommentContainer: "flex justify-between flex-col p-6 pb-2 md:p-4 pt-0",
  gmCommentWrapper: "flex flex-col text-left w-full",
  textAreaWrapper: "flex w-full my-2",
};

const GMClarifications = ({
  // gmQuery,
  // gmComment,
  // gmClarification,
  // gmNewComment,
  comments,
  data,
  onCommentChange,
  showButton
}) => {
  return (
    <>
      {/* 
      TEMPORARILY DISABLED
      <Box className={styles.gmClarificationAnswerRibbon}>
        <Box>
          <Typography className={styles.gmQueryClarificationLabel}>
            GM Queries
          </Typography>
          <Typography className={styles.gmQueryClarificationValue}>
            {gmQuery}
          </Typography>
        </Box>
        <Box>
          <Typography className={styles.gmQueryClarificationLabel}>
            Clarification
          </Typography>
          <Typography className={styles.gmQueryClarificationValue}>
            {gmClarification}
          </Typography>
        </Box>
      </Box> */}
      <Box className={styles.gmCommentContainer}>
        {data.map((approver) => (
          <>
            <Box className={styles.gmCommentWrapper}>
              {approver.status !== "released" && approver?.comments && (
                <>
                  <Typography className={styles.gmCommentLabel}>
                    <span className="uppercase mr-1">{approver.approver}</span>
                    Comments
                  </Typography>
                  <Typography className={styles.gmCommentValue}>
                    {approver?.comments}
                  </Typography>
                </>
              )}
              {approver.status === "inProgress" &&  showButton && (
                <>
                  <Typography className={styles.gmCommentLabel}>
                    <span className="uppercase mr-1">{approver.approver}</span>
                    Comments
                  </Typography>
                  <Box className={styles.textAreaWrapper}>
                    <Textarea
                      placeholder="Type Comment..."
                      value={comments}
                      onChange={onCommentChange}
                      className={"bg-slate-200"}
                    />
                  </Box>
                </>
              )}
            </Box>
          </>
        ))}
      </Box>
    </>
  );
};

export default GMClarifications;
