import React from "react";
import { SearchIcon } from "../assets";
import { twMerge } from "../utils";

const SearchField = ({
  className,
  placeholder,
  value,
  onSearch,
  disabled = false,
}) => {
  return (
    <div
      className={twMerge(
        "flex items-center bg-secondary/10 gap-2 p-3",
        className
      )}
    >
      <img className="w-5" src={SearchIcon} alt="power-wallet-search" />
      <input
        disabled={disabled}
        className="w-full outline-none bg-transparent text-sm disabled:cursor-not-allowed"
        placeholder={placeholder}
        onChange={onSearch}
        value={value}
      />
    </div>
  );
};

export default SearchField;
