import React from "react";
import Box from "../Box";
import Typography from "../Typography";
import { twMerge } from "../../utils";

const styles = {
  expenseStatusRibbon: "w-full text-white p-6 text-center text-sm font-normal",
};

const InvoiceStatusRibbon = ({ status, isClarified }) => {
  const getStatusColor = (status, isClarified) => {
    let statusObj = {
      pending: "bg-orange-700",
      rejected: "bg-red-700",
      awaitingClarification: isClarified ? "bg-green-600" : "bg-yellow-500",
      approved: "bg-green-700",
    };

    return statusObj[status] || "bg-slate-400";
  };

  const getStatusText = (status, isClarified) => {
    let statusObj = {
      pending: "Pending",
      rejected: "Rejected",
      awaitingClarification: isClarified
        ? "Clarified"
        : "Awaiting Clarification",
      approved: "Expense Approved",
    };

    return statusObj[status] || "NA";
  };

  return (
    <Box
      className={twMerge(
        styles.expenseStatusRibbon,
        getStatusColor(status, isClarified)
      )}
    >
      <Typography>{getStatusText(status, isClarified)}</Typography>
    </Box>
  );
};

export default InvoiceStatusRibbon;
