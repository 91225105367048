import React from "react";
import Box from "../Box";
import Button from "../Button";
import Modal from "../Modal";
import Textarea from "../Textarea";
import Typography from "../Typography";

const styles = {
  modalContainer: "flex justify-center flex-col items-center py-2 w-fit",
  modalHeaderText: "text-base font-semibold text-center",
  modalSubText: "mt-4 text-base text-center font-medium w-8/12",
  actionContainer:
    "flex mt-4 gap-4 justify-center flex-row items-center w-full",
  buttonStyle: "w-32",
  clarifyText: "mt-2 mb-2 text-slate-600 text-xs self-start w-60",
};

export const ApproveClaimModal = ({
  showApproveModal,
  onCloseApprove,
  onSubmitApprove,
  loading
}) => {
  return (
    <Modal open={showApproveModal} onClose={onCloseApprove}>
      <Box className={styles.modalContainer}>
        <Typography className={styles.modalHeaderText}>
          Approve Expense
        </Typography>
        <Typography className={styles.modalSubText}>
          Do you want to approve this expense?
        </Typography>
        <Box className={styles.actionContainer}>
          <Button
            className={styles.buttonStyle}
            variant="outlined-primary"
            onClick={onCloseApprove}
          >
            NO
          </Button>
          <Button
            className={styles.buttonStyle}
            variant="filled-primary"
            onClick={onSubmitApprove}
            disabled={loading}
          >
            YES
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export const DeclineClaimModal = ({
  showDeclineModal,
  onCloseDecline,
  onSubmitDecline,
  loading
}) => {
  return (
    <Modal open={showDeclineModal} onClose={onCloseDecline}>
      <Box className={styles.modalContainer}>
        <Typography className={styles.modalHeaderText}>
          Decline Expense
        </Typography>
        <Typography className={styles.modalSubText}>
          Would you like to decline this expense?
        </Typography>
        <Box className={styles.actionContainer}>
          <Button
            className={styles.buttonStyle}
            variant="outlined-primary"
            onClick={onCloseDecline}
          >
            NO
          </Button>
          <Button
            className={styles.buttonStyle}
            variant="filled-critical"
            onClick={onSubmitDecline}
            disabled={loading}
          >
            YES
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export const ClarifyClaimModal = ({
  showClarifyModal,
  onCloseClarify,
  onSubmitClarify,
  onClarifyCommentChange,
  clarifyComments,
  loading
}) => {
  return (
    <Modal open={showClarifyModal} onClose={onCloseClarify}>
      <Box className={styles.modalContainer}>
        <Typography className={styles.modalHeaderText}>
          Need Clarification
        </Typography>
        <Typography className={styles.clarifyText}>
          Please mention your Queries<span className="text-red-600">*</span>
        </Typography>
        <Textarea
          rows={3}
          onChange={onClarifyCommentChange}
          value={clarifyComments}
        />
        <Box className={styles.actionContainer}>
          <Button
            className={styles.buttonStyle}
            variant="outlined-primary"
            onClick={onCloseClarify}
          >
            CANCEL
          </Button>
          <Button
            className={styles.buttonStyle}
            variant="filled-primary"
            onClick={onSubmitClarify}
            disabled={loading}
          >
            SUBMIT
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
