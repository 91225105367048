export const CLAIMERS_ACTIONS = {
  CALL_CLAIMERS_API: "call_claimers_api",
  SET_PAGE: "set_page",
  SET_SEARCH_TEXT: "set_search_text",
};

export const claimersReducer = (state, action) => {
  switch (action.type) {
    case CLAIMERS_ACTIONS.CALL_CLAIMERS_API: {
      return {
        ...state,
        claimersData: action.data,
        claimersDataCount: action.count,
      };
    }
    case CLAIMERS_ACTIONS.SET_PAGE: {
      return {
        ...state,
        page: action.page,
      };
    }
    case CLAIMERS_ACTIONS.SET_SEARCH_TEXT: {
      return {
        ...state,
        page: 1,
        searchText: action.searchText,
      };
    }
  }
};

export const claimersInitialState = {
  page: 1,
  searchText: "",
  claimersData: [],
  claimersDataCount: 0,
  rowsPerPage: 10,
};
