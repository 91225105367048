import React from "react";
import Box from "../Box";

// STYLES
const styles = {
  option:
    "bg-white py-2.5 text-sm border-2 border-secondary/50 text-font-primary text-center rounded",
  activeOption:
    "bg-white py-2.5 text-sm border-2 border-secondary/50 text-secondary text-center rounded",
};

const DateFilterOption = ({ option, onFilterSelect, activeFilter }) => {
  return (
    <Box
      onClick={() => onFilterSelect(option)}
      className={
        styles[activeFilter === option.value ? "activeOption" : "option"]
      }
    >
      {option.label}
    </Box>
  );
};

export default DateFilterOption;
