import React from "react";
import Box from "../Box";
import Typography from "../Typography";
import { twMerge } from "../../utils";

const styles = {
  trackRequestWrapper:
    "flex flex-col w-full items-center bg-slate-100 py-4 border-b border-slate-300",
  trackRequestLabel: "text-center font-semibold",
  trackRequestRoleWrapper:
    "flex relative h-8 items-center my-4  md:overflow-auto md:h-6",
  roleChipWrapper: "h-full w-full flex justify-center",
  roleChip:
    "h-full w-fit px-2 text-white rounded flex justify-center items-center text-xs uppercase md:px-1",
};

const TrackRequest = ({ data }) => {
  return (
    <Box className={styles.trackRequestWrapper}>
      <Typography className={styles.trackRequestLabel}>
        Track Request
      </Typography>
      <Box className={styles.trackRequestRoleWrapper}>
        <Box className={styles.roleChipWrapper}>
          {data.length > 0 &&
            data.map((approverData, index) => (
              <Box className="flex items-center">
                <Box
                  className={twMerge(styles.roleChip)}
                  style={{ background: approverData?.colorCode || "#CCC" }}
                >
                  {approverData?.approver}
                </Box>
                {data?.length !== index + 1 && (
                  <Box
                    className="h-0.5 w-4 md:w-2"
                    style={{ background: approverData?.colorCode || "#CCC" }}
                  />
                )}
              </Box>
            ))}
        </Box>
      </Box>
    </Box>
  );
};

export default TrackRequest;
