import React from "react";
import Box from "../Box";
import Typography from "../Typography";
import CountBadge from "../CountBadge";
import Button from "../Button";
import { ChevronDownIcon } from "../../assets";
import Icon from "../Icon";
import { twMerge } from "../../utils";

const styles = {
  expenseListHeader:
    "flex w-1/4 border-r border-b h-20 justify-between items-center p-4 lg:w-full lg:border-0 lg:pb-0",
  expenseDetailsHeader:
    "flex items-center justify-between p-4 w-3/4 h-20 border-b lg:w-full lg:pt-0",
  claimantNameText: "text-font-primary text-base",
  claimantCompanyNameText: "text-font-secondary text-sm",
  invoiceNumberLabel: "text-font-secondary text-sm",
  invoiceNumberText: "text-font-secondary text-sm",
};

const ClaimDetailsPanelHeader = ({
  activeInvoiceNumber,
  totalInvoiceCount,
  claimantName,
  claimantCompany,
  showInvoiceList,
  onToggleInvoiceList,
  noPendingClaims,
}) => {
  return (
    <Box className="flex flex-row lg:flex-col">
      <Box className={styles.expenseListHeader}>
        <Typography className="text-primary font-medium">
          No. Of Claims
        </Typography>
        <Box className="flex items-center gap-4">
          <CountBadge count={totalInvoiceCount} />
          <Button onClick={onToggleInvoiceList}>
            <Icon
              className={twMerge(
                "h-5 w-5 hidden lg:block",
                showInvoiceList ? "rotate-180" : ""
              )}
              source={ChevronDownIcon}
              alt=""
            />
          </Button>
        </Box>
      </Box>
      {!noPendingClaims && (
        <Box
          className={twMerge(
            styles.expenseDetailsHeader,
            `lg:${!showInvoiceList ? "flex" : "hidden"}`
          )}
        >
          <Box>
            <Typography className={styles.claimantNameText}>
              {claimantName}
            </Typography>
            <Typography className={styles.claimantCompanyNameText}>
              {claimantCompany}
            </Typography>
          </Box>
          <Box className="text-right">
            <Typography className={styles.invoiceNumberLabel}>
              Invoice No.
            </Typography>
            <Typography className={styles.invoiceNumberText}>
              {activeInvoiceNumber}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ClaimDetailsPanelHeader;
