import axios from "axios";
import { toast } from "react-toastify";
import history from "../config/history";
import { getEncodedHeader } from "./utility";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASEURL + "/api/v1",
});

instance.interceptors.request.use((config) => {
  let md5query = {
    deviceId: "",
    deviceType: "web",
    currentTime: new Date().getTime(),
  };

  let randomNo = Math.floor(1000 + Math.random() * 100000000);
  if (
    localStorage.getItem("deviceId") === null ||
    localStorage.getItem("deviceId") === ""
  ) {
    localStorage.setItem("deviceId", randomNo);
  }
  md5query.deviceId = localStorage.getItem("deviceId");

  const queryString = new URLSearchParams(md5query).toString();

  config.url = `${config.url}?${queryString}`;
  config.headers = {
    "x-value": getEncodedHeader(
      config.data,
      { ...md5query, ...config.params },
      config.method
    ),
  };

  const apiToken = localStorage.getItem("powerwallet-token");
  config.headers.Authorization = `Bearer ${apiToken}`;

  return config;
});

instance.interceptors.response.use(
  (response) => {
    if (response?.status === 401) {
    }
    if (response?.status >= 500) {
      toast.error("SERVER ERROR");
    }
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      toast.error("Session expired, Please Re-login", {
        hideProgressBar: true,
      });
      localStorage.removeItem("powerwallet-token");
      localStorage.removeItem("powerwallet-role");
      localStorage.removeItem("powerwallet-manager");
      localStorage.removeItem("powerwallet-approver");
      setTimeout(() => {
        history.replace("/auth/login");
        window.location.reload();
      }, 2000);
    }

    if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
  }
);

export const http = instance;
