import React from "react";
import Table from "../Table";
import Box from "../Box";
import EmptyClaimState from "./EmptyClaimState";
import BadgeChip from "./BadgeChip";
import CompanyNameColumn from "./CompanyNameColumn";
import dayjs from "dayjs";

const ReportsTable = ({
  data,
  onPageChange,
  isTableDataLoading,
  totalData,
  currentPage,
  rowsPerPage,
}) => {
  const getClassName = (status)=> {
    let colorObj = {
      pending:"bg-orange-600/10 text-orange-600 ring-orange-600",
      awaitingClarification:"bg-yellow-600/10 text-yellow-600 ring-yellow-600",
      approved:"bg-green-600/10 text-green-600 ring-green-600",
      rejected:"bg-red-600/10 text-red-600 ring-red-600"
    }
    return colorObj[status]
  }
  const columns = [
    {
      path: "createdAt",
      label: "Claim Date",
      content: (data) => <Box>{`${dayjs(data?.createdAt).format('DD MMM YYYY')}`}</Box>,
    },
    {
      path: "name_company",
      label: "Name & Company",
      content: (data) => <CompanyNameColumn name={data?.userInfo?.name} company={data?.userInfo?.companyName} isNew={false}/>,

    },
    {
      path: "requestNo",
      label: "Reference ID",
    },
    {
      path: "profitCenter",
      label: "Profit Code",
    },
    {
      path: "profitCenterName",
      label: "Profit Center",
    },
    {
      path: "totalClaimedAmount",
      label: "Pending Amount",
      content: (data) => <Box>{`${data?.currency} ${data?.totalClaimedAmount}`}</Box>,
    },
    {
      path: "status",
      label: "Status",
      content: (data) => <BadgeChip label={data?.status} className={getClassName(data?.status)}/>

    },
  ];
  return (
    <Table
      data={data}
      columns={columns}
      onPageChange={onPageChange}
      isTableDataLoading={isTableDataLoading}
      totalData={totalData}
      currentPage={currentPage}
      rowsPerPage={rowsPerPage}
      onRowClick={() => {
        return;
      }}
      emptyStateComponent={<EmptyClaimState />}
    />
  );
};

export default ReportsTable;
