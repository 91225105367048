import React, { Component } from "react";
import Box from "../Box";
import Typography from "../Typography";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { APPROVAL_ANALYTICS_OPTIONS } from "../../constants";
import { twMerge } from "../../utils";

ChartJS.register(ArcElement, Tooltip, Legend);

const getData = (data) => {
  return {
    labels: ["Approved", "Declined", "Pending", "Clarification"],
    datasets: [
      {
        label: "# of Requests",
        data: [
          data?.approvedCount,
          data?.rejectedCount,
          data?.pendingCount,
          data?.awaitingClarification,
        ],
        backgroundColor: ["#3C50E0", "#6577F3", "#80CAEE", "#0FADCF"],
        borderWidth: 1,
      },
    ],
  };
};

const styles = {
  chartContainer:
    "flex h-fit flex-col items-center w-1/3 md:w-full bg-white p-4 drop-shadow-sm lg:overflow-x-auto",
  chartTitle: "font-semibold text-lg text-primary mb-4 self-start",
  chartWrapper:
    "w-full relative flex items-center justify-center inset-0 m-auto",
  chartCountWrapper: "absolute flex items-center justify-center flex-col",
  chartLegendContainer: "w-3/4 grid justify-center grid-cols-2 py-2 gap-2 gap-x-6 lg:grid-cols-1",
  legendWrapper: "flex flex-row items-center gap-1",
  legendColor: "w-3 h-3 rounded-full",
  legendLabel: "text-sm",
};

const Approvals = ({ data }) => {
  const analyticsData = getData(data);

  return (
    <Box className={styles.chartContainer}>
      <Typography className={styles.chartTitle}>My Approvals</Typography>
      <Box className={styles.chartWrapper}>
        <Box className={styles.chartCountWrapper}>
          <Typography className="text-xl font-bold">
            {data?.totalCount}
          </Typography>
          <Typography className="text-font-tertiary text-sm xs:text-xs">
            Total Request
          </Typography>
        </Box>
        <Doughnut data={analyticsData} options={APPROVAL_ANALYTICS_OPTIONS} />
      </Box>
      <Box className={styles.chartLegendContainer}>
        {analyticsData.datasets[0].backgroundColor.map((color, idx) => (
          <Box className="flex flex-row items-center justify-between max-w-24 lg:max-w-40" key={idx}>
            <Box className={styles.legendWrapper}>
              <Box
                component="span"
                className={twMerge(styles.legendColor)}
                style={{ backgroundColor: `${color}` }}
              ></Box>
              <Typography className={styles.legendLabel}>
                {analyticsData.labels[idx]}
              </Typography>
            </Box>
            <Box>
              <Typography className={styles.legendLabel}>
                {Math.round(
                  (analyticsData?.datasets[0]?.data[idx] / data?.totalCount) *
                    100 || 0
                )}
                %
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Approvals;
