import React, { useEffect, useState } from "react";
import Box from "../Box";
import Typography from "../Typography";
import { useClickAway } from "../../hooks";
import { getNotifications } from "../../services/notificationService";
import { toast } from "react-toastify";
import { DEFAULT_ERROR_MESSAGE } from "../../constants";
import { LoaderSVG } from "../../assets";
import Image from "../Image";

// STYLES
const styles = {
  notificationListContainer:
    "flex flex-col divide-y divide-slate-200 max-h-96 min-h-96 overflow-auto",
  notificationContainer: "flex flex-row items-center justify-between p-1",
  updateContainer: "flex flex-col items-start w-9/12",
  timeDateContainer: "text-xs text-font-secondary text-right w-3/12",
  updateText: "text-sm text-font-primary font-medium",
  statusText: "text-sm text-font-secondary",
  timeDateText: "text-xs text-font-secondary",
};

const NotificationData = () => {
  const [data, setData] = useState([]);
  const [loading, setLoader] = useState(false);
  useEffect(() => {
    fetchNotification();
  }, []);

  const fetchNotification = async () => {
    setLoader(true);
    try {
      let res = await getNotifications();
      setData(res?.data?.body?.data);
    } catch (err) {
      toast.error(err.message || DEFAULT_ERROR_MESSAGE);
    } finally {
      setLoader(false);
    }
  };

  return (
    <Box className={styles.notificationListContainer}>
      {loading ? (
        <Box className="flex flex-col items-center justify-center p-4">
          <Image source={LoaderSVG} className="w-16 h-16" />
          <Typography className="text-sm text-font-tertiary">Loading Notifications...</Typography>
        </Box>
      ) : (
        <>
          {data.map((notification) => (
            <Box className={styles.notificationContainer}>
              <Box className={styles.updateContainer}>
                <Typography className={styles.updateText}>
                  {notification?.title}
                </Typography>
                <Typography className={styles.statusText}>
                  {notification?.body}
                </Typography>
              </Box>
              <Box className={styles.timeDateContainer}>
                <Typography className={styles.timeDateText}>
                  11 Dec 2023
                </Typography>
                <Typography className={styles.timeDateText}>
                  03:16 pm
                </Typography>
              </Box>
            </Box>
          ))}
        </>
      )}
    </Box>
  );
};

export default NotificationData;
