import React from "react";
import Box from "./Box";

// STYLES
const styles = {
  countBadge:
    "flex h-6 w-6 bg-slate-200 rounded items-center justify-center text-font-primary",
};

const CountBadge = ({ count = 0 }) => {
  return <Box className={styles.countBadge}>{count}</Box>;
};

export default CountBadge;
