import React from "react";
import Box from "../Box";
import Typography from "../Typography";
import { twMerge } from "../../utils";
import dayjs from "dayjs";

const styles = {
  labelTexts: "text-font-secondary text-sm",
  valueTexts: "text-font-primary text-sm",
  commentValueText: "text-font-primary text-base break-words",
  amountText: "text-font-primary text-4xl font-semibold",
  requestedDateText: "text-secondary text-sm",
  entityWrapper: "flex justify-between p-6 pb-2 md:gap-3 md:p-4 md:pb-0",
  amountWrapper: "flex items-center justify-end md:justify-center",
};

const InvoiceSummary = ({
  activeClaim: {
    invoiceAmount,
    createdAt,
    invoiceDate,
    supplier,
    profitCenter,
    profitCenterName,
    transactionAmount,
    totalClaimedAmount,
    documentNumber
  },
}) => {
  return (
    <>
      <Box className={twMerge(styles.entityWrapper, "md:flex-col-reverse")}>
        <Box className="text-left flex-1">
          <Typography className={styles.labelTexts}>Invoice Date</Typography>
          <Typography className={styles.valueTexts}>
            {dayjs(invoiceDate).format("DD MMM YYYY")}
          </Typography>
        </Box>
        <Box className="text-center md:text-left flex-1">
          <Typography className={styles.labelTexts}>Profit Center Name</Typography>
          <Typography className={styles.valueTexts}>{`${profitCenterName || "NA"} (${profitCenter || '-'})`}</Typography>
        </Box>
        <Box className="text-right md:text-center md:mb-4 flex-1">
          <Box className={styles.amountWrapper}>
            <Typography className={styles.amountText}>
              {totalClaimedAmount}
            </Typography>
            <Typography
              className={twMerge(styles.commentValueText, "font-semibold")}
            >
              QAR
            </Typography>
          </Box>
          <Typography className={styles.requestedDateText}>
            Requested Date - {dayjs(createdAt).format("DD MMM YYYY")}
          </Typography>
        </Box>
      </Box>
      <Box className={twMerge(styles.entityWrapper, "md:flex-col")}>
        <Box className="text-left flex-1">
          <Typography className={styles.labelTexts}>Supplier</Typography>
          <Typography className={styles.commentValueText}>
            {supplier}
          </Typography>
        </Box>
        <Box className="text-center md:text-left flex-1">
          <Typography className={styles.labelTexts}>
            Transaction Amount
          </Typography>
          <Typography
            className={styles.commentValueText}
          >{`QAR ${transactionAmount}`}</Typography>
        </Box>
        <Box className="text-right md:text-left flex-1">
          <Typography className={styles.labelTexts}>Invoice Amount</Typography>
          <Typography
            className={styles.commentValueText}
          >{`QAR ${invoiceAmount}`}</Typography>
        </Box>
      </Box>
      <Box className={twMerge(styles.entityWrapper, "md:flex-col")}>
      <Box className="text-left flex-1">
          <Typography className={styles.labelTexts}>SAP Document ID</Typography>
          <Typography className={styles.commentValueText}>{documentNumber || 'NA'}</Typography>
        </Box>
      </Box>

    </>
  );
};

export default InvoiceSummary;
