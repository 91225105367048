import React, { Component } from "react";
import Box from "../Box";
import Typography from "../Typography";
import CategoriesLabels from "./CategoriesLabels";
import CategoriesCount from "./CategoriesCount";

const styles = {
  chartContainer:
    "w-2/3 h-fit md:w-full bg-white p-4 drop-shadow-sm lg:overflow-x-scroll",
  chartTitle: "font-semibold text-lg text-primary mb-4",
  chartWrapper: "flex flex-col gap-2 xs:w-[475px]",
};

const Categories = ({ data }) => {
  return (
    <Box className={styles.chartContainer}>
      <Typography className={styles.chartTitle}>Categories</Typography>
      <Box className={styles.chartWrapper}>
        <CategoriesLabels />
        {data.map((category) => (
          <CategoriesCount
            value={`${
              Math.round(
                ((category?.approved_count /
                  (category?.pending_count +
                    category?.approved_count +
                    category?.rejected_count)) *
                  100) /
                  10
              ) * 10
            }%`}
            label={category.name || '-'}
            pending={category?.pending_count}
            approved={category?.approved_count}
            rejected={category?.rejected_count}
          />
        ))}
      </Box>
    </Box>
  );
};

export default Categories;
