import React from "react";
import TableContainer from "./TableContainer";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";
import { Pagination } from "./Pagination";
import Box from "../Box";
import List from "./List";
import ListContainer from "./ListContaineer";

const Table = ({
  data,
  columns,
  onPageChange,
  isTableDataLoading,
  totalData,
  currentPage,
  rowsPerPage,
  onRowClick,
  emptyStateComponent
}) => {
  return (
    <Box className="w-full">
      <Box className="overflow-x-auto">
        <TableContainer className="w-full md:hidden">
          <TableHeader columns={columns} />
          <TableBody
            data={data}
            isTableDataLoading={isTableDataLoading}
            columns={columns}
            onRowClick={onRowClick}
            emptyStateComponent={emptyStateComponent}
          />
        </TableContainer>
        <ListContainer className="w-full hidden md:block">
          <List
            data={data}
            isTableDataLoading={isTableDataLoading}
            columns={columns}
            onRowClick={onRowClick}
            emptyStateComponent={emptyStateComponent}
          />
        </ListContainer>
      </Box>
      {data?.length > 0 && (
        <Pagination
          onPageChange={onPageChange}
          isTableDataLoading={isTableDataLoading}
          totalData={totalData}
          currentPage={currentPage}
          rowsPerPage={rowsPerPage}
          totalPages={Math.ceil(totalData / rowsPerPage)}
        />
      )}
    </Box>
  );
};

export default Table;
