import React from "react";
import { getIcon } from "./utility";

const Checkbox = ({
  leftLabel,
  rightLabel,
  selected = false,
  onToggle,
  className,
}) => {
  return (
    <button onClick={() => onToggle(!selected)} className="flex gap-1 flex-row">
      {leftLabel && (
        <span className={className ? className : "text-secondary text-sm text-right"}>{leftLabel}</span>
      )}
      {<span>{getIcon(selected)}</span>}
      {rightLabel && (
        <span className={className ? className : "text-secondary text-sm text-left"}>{rightLabel}</span>
      )}
    </button>
  );
};

export default Checkbox;
