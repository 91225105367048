export const routes = [
  {
    label: "Dashboard",
    path: "dashboard",
    key: "dashboard",
  },
];

export const managerRoutes = [
  {
    label: "Reports",
    path: "reports",
    key: "reports",
  },
];

export const approverRoutes = [
  {
    label: "Claim Requests",
    path: "claims",
    key: "claims",
  },
];
