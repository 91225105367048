import React, { useState, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

// LAYOUTS
import DashboardLayout from "./layouts/DashboardLayout";
import AuthenticationLayout from "./layouts/AuthenticationLayout";

// PAGES
import Dashboard from "./pages/Dashboard";
import Claims from "./pages/Claims";
import ClaimDetails from "./pages/ClaimDetails";
import Login from "./pages/Login";
import NotFound from "./pages/404Page";
import Requests from "./pages/Requests";
import Loader from "./components/Loader";
import Reports from "./pages/Reports";

import { LoadContext } from "./contexts/LoaderContext";
import { UserContext } from "./contexts/UserContext";

const App = () => {
  const [user, setUser] = useState({
    isManager: false,
    isApprover: false,
    roles: [],
  });

  useEffect(() => {
    setUser({
      ...user,
      isApprover: JSON.parse(localStorage.getItem("powerwallet-approver")),
      isManager: JSON.parse(localStorage.getItem("powerwallet-manager")),
      roles: JSON.parse(localStorage.getItem("powerwallet-role")),
    });
  }, []);
  const [loading, setLoader] = useState(false);
  return (
    <LoadContext.Provider value={{ loading, setLoader }}>
      <UserContext.Provider value={{ user, setUser }}>
        {loading && <Loader />}
        <Routes>
          <Route path="auth" element={<AuthenticationLayout />}>
            <Route path="login" element={<Login />} />
            <Route index element={<Navigate to={"login"} replace={true} />} />
          </Route>
          <Route path="admin" element={<DashboardLayout />}>
            <Route path="dashboard" element={<Dashboard />} />
            {user.isApprover && <Route path="claims" element={<Claims />} />}
            {user.isApprover && (
              <Route path="claim/:id" element={<ClaimDetails />} />
            )}
            {user.isApprover && (
              <Route path="requests" element={<Requests />} />
            )}
            {user.isManager && <Route path="reports" element={<Reports />} />}
            <Route index element={<Navigate to="dashboard" replace={true} />} />
          </Route>
          <Route index element={<Navigate to={"auth"} replace={true} />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </UserContext.Provider>
    </LoadContext.Provider>
  );
};

export default App;
