// TEMPORARY CONSTANTS
export const DUMMY_AVATAR =
  "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80";



export const DUMMY_CLAIM_DETAILS = [
  {
    name: "Test",
    company: "Test",
    currency:'QAR',
    employeeId: "23423",
    pendingCount: "2",
    code:"KJBDJK23",
    amount: "3210.00",
    
    },
  {
    name: "Test",
    company: "Test",
    currency:'QAR',
    employeeId: "23423",
    pendingCount: "2",
    code:"KJBDJK23",
    amount: "3210.00",

  },
  {
    name: "Test",
    company: "Test",
    currency:'QAR',
    employeeId: "2344",
    pendingCount: "1",
    code:"KJBDJK23",
    amount: "3210.00",
  },
];

export const CLAIMS_STATUS_FILTER = [
  { label: "Pending", value: "pending" },
  { label: "Approved", value: "approved" },
  { label: "Declined", value: "declined" },
  { label: "All", value: "all" },
];

export const CLAIM_REQUEST_DATE_FILTER = [
  { label: "Overall", value: "" },
  { label: "Today", value: "today" },
  { label: "Yesterday", value: "yesterday" },
  { label: "Current Week", value: "thisWeek" },
  { label: "Current Month", value: "thisMonth" },
  { label: "Custom", value: "custom" },
];

export const REPORTS_STATUS_FILTER = [
  { value: "All", key: "" },
  { value: "Pending", key: "pending,awaitingClarification" },
  { value: "Approved", key: "approved" },
  { value: "Declined", key: "rejected" },
];

export const DEFAULT_ERROR_MESSAGE = "Something went wrong!";

export const REQUEST_ANALYTICS_OPTIONS = {
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    x: {
      grid: { display: false },
      stacked: true,
      tickOptions: { showGridline: false },
    },
    y: {
      stacked: true,
    },
  },
};

export const APPROVAL_ANALYTICS_OPTIONS = {
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
};
