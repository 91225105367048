import React, { useState } from "react";
import { ChevronDownIcon, Hamburger } from "../../assets";
import { DUMMY_AVATAR } from "../../constants";
import Notification from "./Notification";
import ProfileCard from "./ProfileCard";
import Box from "../Box";
import Button from "../Button";
import Icon from "../Icon";

// STYLES
const styles = {
  navbarContainer:
    "flex h-20 bg-white border-b border-slate-200 justify-between px-8 lg:px-2 lg:h-16",
  notificationContainer: "flex flex-row mr-8 items-center gap-4 lg:mr-4",
};

const NavigationBar = ({ onMenuClick, userInfo }) => {
  const [showNotification, setShowNotification] = useState(false);
  const [showprofile, setShowprofile] = useState(false);

  return (
    <Box component="nav" className={styles.navbarContainer}>
      <Box className="flex items-center">
        <Button className="hidden lg:block" onClick={onMenuClick}>
          <Icon source={Hamburger} className="w-8 h-8" />
        </Button>
      </Box>
      <Box className="flex items-center">
        <Box className={styles.notificationContainer}>
          <Notification
            showNotification={showNotification}
            onClickNotification={() => setShowNotification(!showNotification)}
            onCloseDropDown={() => setShowNotification(false)}
          />
        </Box>

        <ProfileCard
          userInfo={userInfo}
          showProfile={showprofile}
          onClickProfile={() => setShowprofile(!showprofile)}
          onCloseDropDown={() => setShowprofile(false)}
        />

      </Box>
    </Box>
  );
};

export default NavigationBar;
