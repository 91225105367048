import React from "react";
import _ from "underscore";

const TableBody = ({ columns, data, isTableDataLoading, onRowClick,emptyStateComponent }) => {
  const renderCell = (item, column) => {
    if (column.content) return column.content(item);
    return _.get(item, column.path);
  };

  const createKey = (item, column) => {
    return item.id + (column.path || column.key);
  };

  return (
    <>
      {!isTableDataLoading ? (
        <>
          {data.length > 0 ? (
            <tbody>
              {data?.map((item, idx) => (
                <tr
                  className="border-b border-slate-100"
                  key={idx}
                  onClick={() => onRowClick(item)}
                >
                  {columns?.map((column) => (
                    <td key={createKey(item, column)}>
                      <div className="px-4 py-2 text-font-primary text-sm">
                        {renderCell(item, column)}
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td
                  className="p-12 text-center text-font-secondary"
                  colSpan={columns.length}
                >
                  {emptyStateComponent || "No Records Found"}
                </td>
              </tr>
            </tbody>
          )}
        </>
      ) : (
        <tbody>
          <tr>
            <td
              className="p-12 text-center text-font-secondary"
              colSpan={columns.length}
            >
              {"Loading..."}
            </td>
          </tr>
        </tbody>
      )}
    </>
  );
};

export default TableBody;
