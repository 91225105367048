import React from "react";
import Box from "../Box";
import Typography from "../Typography";

const styles = {
  categoriesLabelContainer: "relative w-full",
  categoriesWhiteSpace: "bg-white w-full h-6 rounded relative flex",
  categoriesEntityContainer: "absolute right-0 top-0",
  categoriesEntityWrapper: "flex items-center gap-2 h-6",
  categoriesLabel: "w-24 text-center text-font-tertiary text-sm",
};

const CategoriesLabels = ({
  label = ["Requested", "Approved", "Declined"],
}) => {
  return (
    <Box className={styles.categoriesLabelContainer}>
      <Box className="gap-2">
        <Box className={styles.categoriesWhiteSpace}></Box>
      </Box>
      <Box className={styles.categoriesEntityContainer}>
        <Box className={styles.categoriesEntityWrapper}>
          {label.map((val,idx) => (
            <Typography key={idx} className={styles.categoriesLabel}>{val}</Typography>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default CategoriesLabels;
