import React from "react";
import SearchField from "../SearchField";
import Box from "../Box";
import Typography from "../Typography";
import { ApprovedInvoice, DeclinedInvoice } from "../../assets";
import Icon from "../Icon";
import { twMerge } from "../../utils";
import Checkbox from "../Checkbox";
import Button from "../Button";

const styles = {
  expenseListContainer:
    "w-1/4 border-r lg:w-full relative lg:pt-0 overflow-y-auto",
  expenseListContainerScroll: {
    height: `calc(100vh - 18rem)`,
    minHeight: `calc(100vh - 18rem)`,
  },
  activeInvoiceItem: "bg-slate-200 rounded",
  invoiceItem: "flex items-center justify-between p-4",
  invoiceNumberText: "text-font-primary text-sm",
  invoiceAmount: "text-font-secondary text-sm",
  searchField: "w-full rounded flex-row-reverse my-2 lg:hidden",
};

const getStatusIcon = (status) => {
  let statusObj = { approved: ApprovedInvoice, rejected: DeclinedInvoice };
  return statusObj[status];
};

const InvoiceListWithSearch = ({
  searchValue,
  onSearch,
  invoiceList,
  showInvoiceList,
  activeInvoiceNumber,
  onBatchSelectToggle,
  isBatchSelected,
  onApproveAll,
  onClaimClick,
  activeStatus,
}) => {
  return (
    <Box
      className={twMerge(
        styles.expenseListContainer,
        `lg:${showInvoiceList ? "block" : "hidden"}`
      )}
      style={styles.expenseListContainerScroll}
    >
      <Box className="px-4">
        <SearchField
          className={styles.searchField}
          placeholder="Search..."
          value={searchValue}
          onSearch={onSearch}
        />
      </Box>
      <Box className="flex items-end p-4 flex-col gap-4">
        <Button
          disabled={!isBatchSelected}
          className="w-64 md:w-full rounded hidden lg:block"
          variant="filled-primary"
          onClick={onApproveAll}
        >
          APPROVE ALL
        </Button>
        {activeStatus === "pending" && (
          <Checkbox
            onToggle={onBatchSelectToggle}
            selected={isBatchSelected}
            leftLabel="Batch Approve: Select All"
          />
        )}
      </Box>
      <Box className="px-4">
        {invoiceList.map((val, idx) => (
          <Box
            onClick={() => onClaimClick(val)}
            className={twMerge(
              styles.invoiceItem,
              activeInvoiceNumber === val?.invoiceNo
                ? styles.activeInvoiceItem
                : ""
            )}
          >
            <Box>
              <Typography
                className={styles.invoiceNumberText}
              >{`Invoice No. ${val?.invoiceNo}`}</Typography>
              <Typography className={styles.invoiceAmount}>{`QAR ${
                val?.invoiceAmount || 0
              }`}</Typography>
            </Box>

            <Box className="w-6 min-w-6">
              <Icon source={getStatusIcon(val)} className="w-6 min-w-6" />
            </Box>
          </Box>
        ))}
      </Box>
      <Box className="py-6" />
      <Box className="flex justify-center items-end sticky py-4  bottom-0 lg:hidden">
        <Button
          disabled={!isBatchSelected || invoiceList?.length <= 0}
          className="w-64 md:w-full rounded"
          variant="filled-primary"
          onClick={onApproveAll}
        >
          APPROVE ALL
        </Button>
      </Box>
    </Box>
  );
};

export default InvoiceListWithSearch;
