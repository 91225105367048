import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const msalInstance = new PublicClientApplication(msalConfig);
if (window.location.hash !== "") {
  console.log("hash found" + window.location.hash);
} else {
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <BrowserRouter>
      <MsalProvider instance={msalInstance}>
        <ToastContainer
          hideProgressBar={true}
          theme="colored"
          autoClose={2000}
          transition={Slide}
        />
        <App />
      </MsalProvider>
    </BrowserRouter>
  );
}
