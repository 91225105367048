import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import NavigationBar from "../components/NavigationBar";
import Box from "../components/Box";
import SideBar from "../components/SideBar";
import { approverRoutes, managerRoutes, routes } from "../routes";
import { getParentPath } from "../helper";
import { getUserInfo } from "../services/userService";
import { toast } from "react-toastify";
import { DEFAULT_ERROR_MESSAGE } from "../constants";
import { useMsal } from "@azure/msal-react";
import { useIsAuthenticated } from "@azure/msal-react";
import { useLoadContext } from "../contexts/LoaderContext";
import { useUserContext } from "../contexts/UserContext";

// STYLES
const styles = {
  layoutContainer: "flex flex-row h-screen font-poppins",
  contentContainer: "flex-1 h-full w-full bg-slate-100",
};

const DashboardLayout = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [activePath, setActivePath] = useState("dashboard");
  const [userInfo, setUserInfo] = useState({});
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { setLoader } = useLoadContext();
  const { user } = useUserContext();

  useEffect(() => {
    let path =
      Object.keys(params).length === 0
        ? location.pathname.split("/")[location.pathname.split("/").length - 1]
        : location.pathname.split("/")[location.pathname.split("/").length - 2];

    let parentPath = getParentPath(path);
    setActivePath(parentPath);
  }, [location]);

  useEffect(() => {
    fetchUserInfo();
  }, []);

  const handleLogout = async () => {
    await localStorage.removeItem("powerwallet-token");
    await localStorage.removeItem("powerwallet-role");
    await localStorage.removeItem("powerwallet-manager");
    await localStorage.removeItem("powerwallet-approver");
    setLoader(true);
    navigate("/auth/login");
    if (isAuthenticated) {
      instance.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/",
      });
    }
    setLoader(false);
  };
  const handleNavigate = (path) => {
    navigate(`/admin/${path}`);
    setSidebarOpen(false);
  };

  const fetchUserInfo = async () => {
    setLoader(true);
    try {
      let res = await getUserInfo();
      setUserInfo(res?.data?.body?.data);
    } catch (err) {
      toast.error(err.message || DEFAULT_ERROR_MESSAGE);
    } finally {
      setLoader(false);
    }
  };

  const getRoutes = (user) => {
    let appRoutes = [...routes];
    user.isManager && appRoutes.push(...managerRoutes);
    user.isApprover && appRoutes.push(...approverRoutes);
    return appRoutes;
  };

  return (
    <Box className={styles.layoutContainer}>
      <SideBar
        onLogout={handleLogout}
        items={getRoutes(user)}
        activePath={activePath}
        onNavigate={handleNavigate}
        sidebarOpen={sidebarOpen}
      />
      <Box className={styles.contentContainer}>
        <NavigationBar
          onMenuClick={() => setSidebarOpen(true)}
          userInfo={userInfo}
        />
        <Box
          component="main"
          style={{ height: `calc(100vh - 5rem)` }}
          className="overflow-y-auto"
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardLayout;
