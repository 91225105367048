import React from "react";
import Box from "../Box";
import Typography from "../Typography";
import _ from "underscore";

const List = ({
  columns,
  data,
  isTableDataLoading,
  onRowClick,
  emptyStateComponent,
}) => {
  const renderCell = (item = {}, column) => {
    if (column.content) return column.content(item);
    return _.get(item, column.path);
  };
  return (
    <>
      {isTableDataLoading ? (
        <Box className="flex justify-center p-4 bg-slate-50 rounded-sm text-font-secondary">
          <Typography>Loading...</Typography>
        </Box>
      ) : (
        <Box className="flex-col gap-8">
          {data.length > 0 ? (
            <>
              {data?.map((dataItem, idx) => (
                <Box className="grid grid-cols-2 gap-y-4 border-b-2 py-4">
                  {columns?.map((column, id) => (
                    <Box
                      key={id}
                      className={id % 2 === 0 ? "text-left" : "text-right"}
                      onClick={() => onRowClick(dataItem)}
                    >
                      <Typography className="text-xs uppercase text-secondary font-semibold">
                        {column?.label}
                      </Typography>
                      <Box>{renderCell(dataItem, column)}</Box>
                    </Box>
                  ))}
                </Box>
              ))}
            </>
          ) : (
            <>
              {emptyStateComponent || (
                <Box className="flex justify-center p-4 bg-slate-50 rounded-sm text-font-secondary">
                  <Typography>No Records Found</Typography>
                </Box>
              )}
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default List;
