import React from "react";
import Box from "../Box";
import Typography from "../Typography";
import RequestCount from "./RequestCount";

const styles = {
  requestCountContainer:
    "grid grid-cols-4 bg-white p-4 drop-shadow-sm divide-x md:grid-cols-2 md:p-0 md:bg-transparent md:divide-x-0 md:gap-2",
  requestCountTitle: "font-semibold text-lg text-primary mb-4 hidden md:block",
};

const TotalRequestsCount = ({ data }) => {
  return (
    <Box>
      <Typography className={styles.requestCountTitle}>
        Total Request
      </Typography>
      <Box className={styles.requestCountContainer}>
        <RequestCount label="Total Approved" count={data?.approvedCount} />
        <RequestCount label="Total Pending" count={data?.pendingCount} />
        <RequestCount label="Total Declined" count={data?.rejectedCount} />
        <RequestCount
          label="Awaiting Clarification"
          count={data?.awaitingClarification}
        />
      </Box>
    </Box>
  );
};

export default TotalRequestsCount;
