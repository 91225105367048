import React from "react";
import Box from "../Box";

// STYLES
const styles = {
  statusFilterWrapper: "flex items-center py-4 gap-2 md:gap-1",
  statusFilterChip:
    "px-2 py-1 bg-white text-secondary w-28 text-center rounded-full cursor-pointer md:text-sm md:w-24",
  statusActiveFilterChip:
    "px-2 py-1 bg-secondary text-white w-28 text-center rounded-full cursor-pointer md:text-sm md:w-24",
};

const StatusFilter = ({ onSelectStatusFilter, active, filterOptions }) => {
  return (
    <Box className={styles.statusFilterWrapper}>
      {filterOptions.map((item, idx) => (
        <Box
          key={idx}
          onClick={() => onSelectStatusFilter(item.key)}
          className={
            styles[
              item.key === active
                ? "statusActiveFilterChip"
                : "statusFilterChip"
            ]
          }
        >
          {item.value}
        </Box>
      ))}
    </Box>
  );
};

export default StatusFilter;
