import { getSidebarIcon } from "../../helper";
import Box from "../Box";
import SidebarItem from "./SidebarItem";
import SidebarLabel from "./SidebarLabel";

// STYLES
const styles = {
  sidebarListContainer: "flex flex-col gap-4 pt-10",
};

const SidebarList = ({ items, activePath, onNavigate }) => {
  return (
    <Box className={styles.sidebarListContainer}>
      <SidebarLabel label="MENU" />
      {items.map((item, index) => (
        <SidebarItem
          icon={getSidebarIcon(item.key)}
          item={item}
          key={index}
          active={item.path === activePath}
          onNavigate={onNavigate}
        />
      ))}
    </Box>
  );
};

export default SidebarList;
