import React from "react";
import Typography from "../Typography";
import Box from "../Box";

const styles = {
  requestCountContainer:
    "text-center md:bg-white md:p-2 md:ring-1 md:ring-slate-200 md:rounded-sm",
  countText: "font-bold text-xl text-font-primary",
  labelText: "text-font-secondary text-sm",
};

const RequestCount = ({ label, count }) => {
  return (
    <Box className={styles.requestCountContainer}>
      <Typography className={styles.countText}>{count}</Typography>
      <Typography className={styles.labelText}>{label}</Typography>
    </Box>
  );
};

export default RequestCount;
