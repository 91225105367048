import React from "react";
import Table from "../Table";
import CompanyNameColumn from "./CompanyNameColumn";
import ExpenseColumn from "./ExpenseColumn";
import BadgeChip from "./BadgeChip";
import { getClaimStatusClass } from "../../helper";
import Box from "../Box";
// import { DUMMY_CLAIM_DETAILS } from "../../constants";
import EmptyClaimState from "./EmptyClaimState";

const ClaimTable = ({
  data,
  onPageChange,
  isTableDataLoading,
  totalData,
  currentPage,
  rowsPerPage,
  onClaimDetailsSelect,
}) => {
  // const data = [];
  // const data = DUMMY_CLAIM_DETAILS;
  const columns = [
    {
      path: "name_company",
      label: "Name & Company",
      content: (data) => (
        <CompanyNameColumn
          name={data?.name}
          company={data?.company}
          isNew={data?.isNew}
        />
      ),
    },
    {
      path: "employeeId",
      label: "Employee ID",
    },
    {
      path: "code",
      label: "Assigned Outlet Code",
    },
    {
      path: "pendingCount",
      label: "Pending Claims",
      content: (data) => <ExpenseColumn count={data?.pendingCount} />,
    },
    {
      path: "amount",
      label: "Pending Amount",
      content: (data) => <Box>{`${data?.currency} ${data?.amount}`}</Box>,
    },
  ];
  return (
    <Table
      data={data}
      columns={columns}
      onPageChange={onPageChange}
      isTableDataLoading={isTableDataLoading}
      totalData={totalData}
      currentPage={currentPage}
      rowsPerPage={rowsPerPage}
      onRowClick={onClaimDetailsSelect}
      emptyStateComponent={<EmptyClaimState />}
    />
  );
};

export default ClaimTable;
